import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { AccountEmailScreen_query$key } from "__relay_artifacts__/AccountEmailScreen_query.graphql";
import EditEmailForm from "./EditEmailForm";

const fragment = graphql`
  fragment AccountEmailScreen_query on Query {
    viewer {
      id

      shop {
        ...EditEmailForm_shop
      }
    }
  }
`

type Props = {
  query: AccountEmailScreen_query$key
}

const AccountEmailScreen: FC<Props> = (props) => {
  const query = useFragment(fragment, props.query);

  return (
    <>
      <div style={{ margin: "30px 0px", display: 'inline-block' }}>
        <h3 style={{marginBottom: "0px"}}>メールアドレス設定</h3>
        <p style={{fontSize: "14px"}}>購入者からのお問い合わせメールがこちらに届きます</p>
      </div>

      <EditEmailForm shop={query.viewer.shop} />
    </>
  )

}

export default AccountEmailScreen;