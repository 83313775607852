/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReportHeader_shop = {
    readonly id: string;
    readonly amountForTransfer: number;
    readonly " $refType": "ReportHeader_shop";
};
export type ReportHeader_shop$data = ReportHeader_shop;
export type ReportHeader_shop$key = {
    readonly " $data"?: ReportHeader_shop$data;
    readonly " $fragmentRefs": FragmentRefs<"ReportHeader_shop">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportHeader_shop",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amountForTransfer",
      "storageKey": null
    }
  ],
  "type": "Shop",
  "abstractKey": null
};
(node as any).hash = '41866fffe01b8c1f7f6fdada7a0e53d9';
export default node;
