import React, { FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { HomeScreen_query$key } from "__relay_artifacts__/HomeScreen_query.graphql";
import ReportHeader from "./ReportHeader";
import TransferList from "./TransferList"

const fragment = graphql`
  fragment ReportScreen_query on Query {
    viewer {
      id

      shop {
        id
        ...ReportHeader_shop
        ...TransferList_shop
      }
    }
  }
`

type Props = {
  query: HomeScreen_query$key
}

const ReportScreen: FC<Props> = (props) => {
  const query = useFragment(fragment, props.query);
  const shop = query.viewer.shop

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "30px 0px" }}>
        <h3>売上レポート</h3>
      </div>

      <ReportHeader shop={shop} />

      <div style={{marginTop: "70px"}}>
        <TransferList shop={shop} />
      </div>
    </>
  )
}

export default ReportScreen;