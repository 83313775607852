import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import AccountShippingScreen from "components/AccountShippingScreen";

type Props = {}
const AccountShipping: FC<Props> = () => {
  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <AccountShippingScreen />
      </Container>
    </>
  )
}

export default AccountShipping;
