import React, { FC } from 'react';
import { Button, styled } from "@mui/material";

type Props = {};

const StyledButton = styled(Button)({
  backgroundColor: "#fafafa",
  color: "#000",
  fontSize: '12px',
  height: '50px',
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#fafafa",
  },
  width: "100%"
});

export const ItemCsvTemplateDownloadButton: FC<Props> = () => {
  return (
    <>
      <StyledButton href={`${process.env.REACT_APP_API_URL}/drop_bulk_upsert.csv`}>一括登録用テンプレートをダウンロード</StyledButton>
    </>
  );
};
