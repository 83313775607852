/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateShopShippingInput = {
    clientMutationId?: string | null;
    fee: number;
    detailedShippings: Array<DetailedShippingInput>;
};
export type DetailedShippingInput = {
    fee: number;
    prefectureId: string;
};
export type EditShippingForm_UpdateShopShippingMutationVariables = {
    input: UpdateShopShippingInput;
};
export type EditShippingForm_UpdateShopShippingMutationResponse = {
    readonly updateShopShipping: {
        readonly shop: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"EditShippingForm_shop">;
        };
    } | null;
};
export type EditShippingForm_UpdateShopShippingMutation = {
    readonly response: EditShippingForm_UpdateShopShippingMutationResponse;
    readonly variables: EditShippingForm_UpdateShopShippingMutationVariables;
};



/*
mutation EditShippingForm_UpdateShopShippingMutation(
  $input: UpdateShopShippingInput!
) {
  updateShopShipping(input: $input) {
    shop {
      id
      ...EditShippingForm_shop
    }
  }
}

fragment EditShippingForm_shop on Shop {
  id
  shipping {
    id
    fee
  }
  detailedShippings {
    id
    fee
    prefecture {
      id
      name
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fee",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditShippingForm_UpdateShopShippingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateShopShippingPayload",
        "kind": "LinkedField",
        "name": "updateShopShipping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditShippingForm_shop"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditShippingForm_UpdateShopShippingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateShopShippingPayload",
        "kind": "LinkedField",
        "name": "updateShopShipping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopShipping",
                "kind": "LinkedField",
                "name": "shipping",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopDetailedShipping",
                "kind": "LinkedField",
                "name": "detailedShippings",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Prefecture",
                    "kind": "LinkedField",
                    "name": "prefecture",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2069ee0a6f9c149d5979c399223f724d",
    "id": null,
    "metadata": {},
    "name": "EditShippingForm_UpdateShopShippingMutation",
    "operationKind": "mutation",
    "text": "mutation EditShippingForm_UpdateShopShippingMutation(\n  $input: UpdateShopShippingInput!\n) {\n  updateShopShipping(input: $input) {\n    shop {\n      id\n      ...EditShippingForm_shop\n    }\n  }\n}\n\nfragment EditShippingForm_shop on Shop {\n  id\n  shipping {\n    id\n    fee\n  }\n  detailedShippings {\n    id\n    fee\n    prefecture {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'cbbb33db4e41ee2ef2832f8f853b7bc7';
export default node;
