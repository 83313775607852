import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { AccountBankScreen_query$key } from "__relay_artifacts__/AccountBankScreen_query.graphql";
import EditBankForm from "./EditBankForm";

const fragment = graphql`
  fragment AccountBankScreen_query on Query {
    viewer {
      ...EditBankForm_user
    }
  }
`

type Props = {
  query: AccountBankScreen_query$key
}

const AccountBankScreen: FC<Props> = (props) => {
  const query = useFragment(fragment, props.query);

  return (
    <>
      <div style={{ margin: "30px 0px", display: 'inline-block', width: "100%" }}>
        <h3 style={{marginBottom: "30px"}}>振り込み先口座</h3>
        <EditBankForm user={query.viewer} />
      </div>
    </>
  )

}

export default AccountBankScreen;