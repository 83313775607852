/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type HomeScreen_query = {
    readonly viewer: {
        readonly id: string;
        readonly shop: {
            readonly readyForLive: boolean;
            readonly " $fragmentRefs": FragmentRefs<"LiveList_shop">;
        };
    } | null;
    readonly " $refType": "HomeScreen_query";
};
export type HomeScreen_query$data = HomeScreen_query;
export type HomeScreen_query$key = {
    readonly " $data"?: HomeScreen_query$data;
    readonly " $fragmentRefs": FragmentRefs<"HomeScreen_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HomeScreen_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Shop",
          "kind": "LinkedField",
          "name": "shop",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "readyForLive",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "LiveList_shop"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '1808c9c2b4a9a7d858136a2d0dd939ae';
export default node;
