/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EmailQueryVariables = {};
export type EmailQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"AccountEmailScreen_query">;
};
export type EmailQuery = {
    readonly response: EmailQueryResponse;
    readonly variables: EmailQueryVariables;
};



/*
query EmailQuery {
  ...AccountEmailScreen_query
}

fragment AccountEmailScreen_query on Query {
  viewer {
    id
    shop {
      ...EditEmailForm_shop
      id
    }
  }
}

fragment EditEmailForm_shop on Shop {
  id
  email
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "EmailQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccountEmailScreen_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "EmailQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "087bc846c18671e06b7fa34df5c85856",
    "id": null,
    "metadata": {},
    "name": "EmailQuery",
    "operationKind": "query",
    "text": "query EmailQuery {\n  ...AccountEmailScreen_query\n}\n\nfragment AccountEmailScreen_query on Query {\n  viewer {\n    id\n    shop {\n      ...EditEmailForm_shop\n      id\n    }\n  }\n}\n\nfragment EditEmailForm_shop on Shop {\n  id\n  email\n}\n"
  }
};
})();
(node as any).hash = '8b8e6e295028a21ffaa8ecd751b31a1f';
export default node;
