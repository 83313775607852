import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/material";
import { useFormikContext, useField, ErrorMessage } from "formik"
import { graphql } from "babel-plugin-relay/macro";
import { handleFetch } from "lib/handleFetch";
import { BankAutoCompleteForm_banks_QueryResponse } from "__relay_artifacts__/BankAutoCompleteForm_banks_Query.graphql";
import StyledErrorMessage from "components/Common/StyledErrorMessage";

const bankQuery = graphql`
  query BankAutoCompleteForm_banks_Query($name: String) {
    banks(first: 5, name: $name) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const OptionName = styled("span")({
  fontSize: 14,
  height: "30px"
});

const StyledFormikTextField = styled(TextField)({
  marginTop: 0,
  background: "#fff",
  minHeight: '47px',
  "& .MuiFormHelperText-root": {
    padding: "10px 14px",
    margin: "0px !important",
    backgroundColor: "#fafafa",
    color: "#bf3c3c"
  }
});

type Props = {
}

const BankAutoCompleteForm: React.FC<Props> = (props) => {
  const [field] = useField("bank");
  const { setFieldValue } = useFormikContext()
  const [bankOptions, setBankOptions] = React.useState([]);

  const handleInputChange = React.useCallback(async (newValue) => {
    const variables = { name: newValue };
    const res = (await handleFetch(bankQuery, variables)) as BankAutoCompleteForm_banks_QueryResponse;

    setBankOptions((res as any).banks.edges.map(e => e.node));
  }, [bankOptions])

  return (
    <>
      <Autocomplete
        fullWidth={true}
        value={field.value}
        freeSolo
        options={bankOptions}
        disableClearable
        getOptionLabel={(option) => option.name || ""}
        filterOptions={(x) => x}
        onChange={(event, newValue) => {
          setFieldValue("bank", newValue)
          setFieldValue("branch", null)
        }}
        onInputChange={(event, newInputValue) => {
          handleInputChange(newInputValue);

          if (newInputValue === "") {
            setFieldValue("bank", null);
            setFieldValue("branch", null);
          }
        }}
        renderInput={(params) => (
          <StyledFormikTextField
            {...params}
            name="bank"
            value={field.value?.name}
            fullWidth={true}
            placeholder="銀行名"
            margin="normal"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              classes: {
              }
            }}
          />

        )}
        renderOption={(props, option) => {
          return <OptionName {...props} key={option.name}>{option.name}</OptionName>;
        }}
      />
      <ErrorMessage name="bank" component={StyledErrorMessage} />
    </>
  )
}

export default BankAutoCompleteForm;