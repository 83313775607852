/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LiveShowQueryVariables = {
    id: string;
};
export type LiveShowQueryResponse = {
    readonly viewer: {
        readonly shop: {
            readonly " $fragmentRefs": FragmentRefs<"LiveShowScreen_shop">;
        };
    } | null;
};
export type LiveShowQuery = {
    readonly response: LiveShowQueryResponse;
    readonly variables: LiveShowQueryVariables;
};



/*
query LiveShowQuery(
  $id: ID!
) {
  viewer {
    shop {
      ...LiveShowScreen_shop
      id
    }
    id
  }
}

fragment LiveShowScreen_shop on Shop {
  live(id: $id) {
    id
    status
    shown
    startAt
    ...LiveStatusText_live
  }
}

fragment LiveStatusText_live on Live {
  id
  status
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveShowQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "LiveShowScreen_shop"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LiveShowQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                  }
                ],
                "concreteType": "Live",
                "kind": "LinkedField",
                "name": "live",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "shown",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startAt",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "981fbe2b0378e34d2b9a97fbe1926c14",
    "id": null,
    "metadata": {},
    "name": "LiveShowQuery",
    "operationKind": "query",
    "text": "query LiveShowQuery(\n  $id: ID!\n) {\n  viewer {\n    shop {\n      ...LiveShowScreen_shop\n      id\n    }\n    id\n  }\n}\n\nfragment LiveShowScreen_shop on Shop {\n  live(id: $id) {\n    id\n    status\n    shown\n    startAt\n    ...LiveStatusText_live\n  }\n}\n\nfragment LiveStatusText_live on Live {\n  id\n  status\n}\n"
  }
};
})();
(node as any).hash = '442066d1717dd23b474c3933ea889375';
export default node;
