import React, { FC, useMemo } from "react"
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { AuctionStatusText_auction$key } from "__relay_artifacts__/AuctionStatusText_auction.graphql";

const fragment = graphql`
  fragment AuctionStatusText_auction on Auction {
    id
    status
  }
`

type Props = {
  auction: AuctionStatusText_auction$key
}

const AuctionStatusText: FC<Props> = (props) => {
  const auction = useFragment(fragment, props.auction);

  const statusText = useMemo(() => {
    switch (auction.status) {
      case "PENDING":
        return "未開催";
      case "FINISHED":
        return "終了";
      case "DROPPED":
        return "開催中"
      default:
        return ""
    }
  }, [auction.status])

  const backgroundColor = useMemo(() => {
    switch (auction.status) {
      case "PENDING":
        return "#5B4DAE";
      case "FINISHED":
        return "#479679";
      case "DROPPED":
        return "#D85E5E"
      default:
        return ""
    }
  }, [auction.status])

  return (
    <span style={{ backgroundColor: backgroundColor, padding: "5px 8px", fontSize: '11px', fontWeight: 'bold', borderRadius: "4px", color: "#fff" }}>
      {statusText}
    </span>
  )
}

export default AuctionStatusText;