/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type PrefectureSelect_query = {
    readonly prefectures: ReadonlyArray<{
        readonly id: string;
        readonly name: string;
    }>;
    readonly " $refType": "PrefectureSelect_query";
};
export type PrefectureSelect_query$data = PrefectureSelect_query;
export type PrefectureSelect_query$key = {
    readonly " $data"?: PrefectureSelect_query$data;
    readonly " $fragmentRefs": FragmentRefs<"PrefectureSelect_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PrefectureSelect_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Prefecture",
      "kind": "LinkedField",
      "name": "prefectures",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '22f640552252b8d5410200a34877cea9';
export default node;
