import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import AccountBankScreen from "components/AccountBankScreen"
import { graphql } from "babel-plugin-relay/macro";
import { BankQuery } from "__relay_artifacts__/BankQuery.graphql";
import { useQuery } from "relay-hooks";

const query = graphql`
  query BankQuery {
    ...AccountBankScreen_query
  }
`

type Props = {}
const AccountBank: FC<Props> = () => {
  const { data } = useQuery<BankQuery>(query, {first: 10});
  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <AccountBankScreen query={data} />
      </Container>
    </>
  )
}

export default AccountBank;
