import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import { ExtraQuery } from "__relay_artifacts__/ExtraQuery.graphql";
import AccountShippingExtraScreen from "components/AccountShippingExtraScreen";

const query = graphql`
  query ExtraQuery {
    ...AccountShippingExtraScreen_query
  }
`

type Props = {}

const AccountShippingExtra: FC<Props> = () => {
  const { data } = useQuery<ExtraQuery>(query, {}, { fetchPolicy: "network-only" });

  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <AccountShippingExtraScreen query={data} />
      </Container>
    </>
  )
}

export default AccountShippingExtra;
