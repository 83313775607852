import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { styled } from "@mui/material";
import { Link } from "react-router-dom";
import { AccountScreen_query$key } from "__relay_artifacts__/AccountScreen_query.graphql";

const fragment = graphql`
  fragment AccountScreen_query on Query {
    viewer {
      id
    }
  }
`

const FlexWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #ECECEC",
  padding: "10px 0px"
})

const StyledButton = styled("div")({
  color: "#fff",
  fontSize: "13px",
  fontWeight: "bold",
  padding: "10px 25px",
  backgroundColor: "#000",
  borderRadius: "4px"
})

const Name = styled("p") ({
  fontSize: "14px",
  fontWeight: "bold"
})

type Props = {
  query: AccountScreen_query$key
}

const AccountScreen: FC<Props> = (props) => {
  const query = useFragment(fragment, props.query);

  return (
    <>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "30px 0px" }}>
        <h3>アカウント設定</h3>
      </div>

      <FlexWrapper>
        <Name>メールアドレス</Name>
        <Link to={`/account/email`}>
          <StyledButton>変更する</StyledButton>
        </Link>
      </FlexWrapper>
      <FlexWrapper>
        <Name>ショップについて</Name>
        <Link to={`/account/bio`}>
          <StyledButton>変更する</StyledButton>
        </Link>
      </FlexWrapper>
      <FlexWrapper>
        <Name>送料</Name>
        <Link to={`/account/shipping`}>
          <StyledButton>変更する</StyledButton>
        </Link>
      </FlexWrapper>
      <FlexWrapper>
        <Name>振込先口座</Name>
        <Link to={`/account/bank`}>
          <StyledButton>変更する</StyledButton>
        </Link>
      </FlexWrapper>
    </>
  )

}


export default AccountScreen;