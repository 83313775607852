import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import { DetailedQuery } from "__relay_artifacts__/DetailedQuery.graphql";
import AccountShippingDetailedScreen from "components/AccountShippingDetailedScreen";

const query = graphql`
  query DetailedQuery {
    ...AccountShippingDetailedScreen_query
  }
`

type Props = {}
const AccountShippingDetailed: FC<Props> = () => {
  const { data } = useQuery<DetailedQuery>(query, {}, { fetchPolicy: "network-only" });

  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <AccountShippingDetailedScreen query={data} />
      </Container>
    </>
  )
}

export default AccountShippingDetailed;
