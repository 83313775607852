/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReportQueryVariables = {};
export type ReportQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ReportScreen_query">;
};
export type ReportQuery = {
    readonly response: ReportQueryResponse;
    readonly variables: ReportQueryVariables;
};



/*
query ReportQuery {
  ...ReportScreen_query
}

fragment ReportHeader_shop on Shop {
  id
  amountForTransfer
}

fragment ReportScreen_query on Query {
  viewer {
    id
    shop {
      id
      ...ReportHeader_shop
      ...TransferList_shop
    }
  }
}

fragment TransferListItem_transfer on Transfer {
  id
  amount
  createdAt
  ...TransferStatusLabel_transfer
}

fragment TransferList_shop on Shop {
  transfers {
    edges {
      node {
        ...TransferListItem_transfer
        id
      }
    }
  }
}

fragment TransferStatusLabel_transfer on Transfer {
  status
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ReportScreen_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amountForTransfer",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "TransferConnection",
                "kind": "LinkedField",
                "name": "transfers",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TransferEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Transfer",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "amount",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d86aafb40eaa12c27189c7b951262eea",
    "id": null,
    "metadata": {},
    "name": "ReportQuery",
    "operationKind": "query",
    "text": "query ReportQuery {\n  ...ReportScreen_query\n}\n\nfragment ReportHeader_shop on Shop {\n  id\n  amountForTransfer\n}\n\nfragment ReportScreen_query on Query {\n  viewer {\n    id\n    shop {\n      id\n      ...ReportHeader_shop\n      ...TransferList_shop\n    }\n  }\n}\n\nfragment TransferListItem_transfer on Transfer {\n  id\n  amount\n  createdAt\n  ...TransferStatusLabel_transfer\n}\n\nfragment TransferList_shop on Shop {\n  transfers {\n    edges {\n      node {\n        ...TransferListItem_transfer\n        id\n      }\n    }\n  }\n}\n\nfragment TransferStatusLabel_transfer on Transfer {\n  status\n}\n"
  }
};
})();
(node as any).hash = 'b2c78a7de55f132e013dc9e20c6dda67';
export default node;
