import React, { FC } from "react";
import LiveForm from "./LiveForm"
import { graphql } from "babel-plugin-relay/macro";
import { NewLiveScreen_query$key } from "__relay_artifacts__/NewLiveScreen_query.graphql"
import { useFragment } from "relay-hooks";

const fragment = graphql`
  fragment NewLiveScreen_query on Query {
    ...LiveForm_query
  }
`

type Props = {
  query: NewLiveScreen_query$key
}

const NewLiveScreen: FC<Props> = (props) => {
  const query = useFragment(fragment, props.query);

  return (
    <>
      <div style={{ padding: "30px 0px" }}>
        <h3>配信作成</h3>

        <LiveForm query={query} />

      </div>
    </>
  )

}

export default NewLiveScreen;