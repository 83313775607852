import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import AuctionListItem from "./AuctionListItem";
import { AuctionList_live$key } from "__relay_artifacts__/AuctionList_live.graphql";

const fragment = graphql`
  fragment AuctionList_live on Live {
    ...AuctionListItem_live

    auctions(first: $first, after: $after)
      @connection(key: "AuctionList_auctions") {
      edges {
        node {
          ...AuctionListItem_auction
        }
      }
    }
  }
`

type Props = {
  live: AuctionList_live$key
}

const AuctionList: FC<Props> = (props) => {
  const live = useFragment(fragment, props.live);

  return (
    <>
      {
        live.auctions.edges.map((edge, i) => {
          const auction = edge!.node;

          return (
            <AuctionListItem auction={auction} live={live} key={i} />
          )
        })
      }
    </>
  )
}

export default AuctionList;