import React from "react";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { styled } from "@mui/material";
import { ErrorMessage, useFormikContext, useField } from "formik"
import { graphql } from "babel-plugin-relay/macro";
import { handleFetch } from "lib/handleFetch";
import { BranchAutoCompleteForm_branches_QueryResponse } from "__relay_artifacts__/BranchAutoCompleteForm_branches_Query.graphql";
import StyledErrorMessage from "components/Common/StyledErrorMessage";

const branchQuery = graphql`
  query BranchAutoCompleteForm_branches_Query($bankId: ID!, $name: String) {
    branches(first: 5, bankId: $bankId, name: $name) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const OptionName = styled("span")({
  fontSize: 14,
  height: "30px"
});

const StyledFormikTextField = styled(TextField)({
  marginTop: 0,
  background: "#fff",
  minHeight: '47px',
  "& .MuiFormHelperText-root": {
    padding: "10px 14px",
    margin: "0px !important",
    backgroundColor: "#fafafa",
    color: "#bf3c3c"
  }
});

type Props = {
}

const BranchAutoCompleteForm: React.FC<Props> = (props) => {
  const [branchOptions, setBranchOptions] = React.useState([]);

  const [field] = useField("branch");
  const [bankField] = useField("bank");
  const { setFieldValue } = useFormikContext()

  const handleInputChange = React.useCallback(async (newValue) => {
    if (!bankField.value) { return; }

    const variables = { bankId: bankField.value.id, name: newValue };
    const res = (await handleFetch(branchQuery, variables)) as BranchAutoCompleteForm_branches_QueryResponse;

    setBranchOptions((res as any).branches.edges.map(e => e.node));
  }, [bankField]);

  return (
    <>
      <Autocomplete
        freeSolo
        value={field.value}
        options={branchOptions}
        getOptionLabel={(option) => option.name || ""}
        filterOptions={(x) => x}
        onChange={(event, newValue) => {
          setFieldValue("branch", newValue)
        }}
        onInputChange={(event, newInputValue) => {
          handleInputChange(newInputValue);

          if (newInputValue === "") {
            setFieldValue("branch", null);
          }
        }}
        disabled={bankField.value === null}
        noOptionsText="選択肢がありません"
        disableClearable
        renderInput={(params) => (
          <StyledFormikTextField
            {...params}
            value={field.value?.name}
            name="branch"
            fullWidth={true}
            placeholder="支店名"
            margin="normal"
            variant="outlined"
            InputProps={{
              ...params.InputProps,
              classes: {
              }
            }}
          />
        )}
        renderOption={(props, option) => {
          return <OptionName {...props}>{option.name}</OptionName>;
        }}
      />
      <ErrorMessage name="branch" component={StyledErrorMessage} />
    </>
  )

}

export default BranchAutoCompleteForm;
