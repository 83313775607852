/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountKind = "CHECKING" | "REGULAR" | "%future added value";
export type EditBankForm_user = {
    readonly id: string;
    readonly account: {
        readonly bank: {
            readonly id: string;
            readonly name: string | null;
        };
        readonly branch: {
            readonly id: string;
            readonly name: string | null;
        };
        readonly name: string;
        readonly number: string;
        readonly kind: AccountKind;
    } | null;
    readonly " $refType": "EditBankForm_user";
};
export type EditBankForm_user$data = EditBankForm_user;
export type EditBankForm_user$key = {
    readonly " $data"?: EditBankForm_user$data;
    readonly " $fragmentRefs": FragmentRefs<"EditBankForm_user">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditBankForm_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "UserAccount",
      "kind": "LinkedField",
      "name": "account",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Bank",
          "kind": "LinkedField",
          "name": "bank",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Branch",
          "kind": "LinkedField",
          "name": "branch",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
(node as any).hash = 'e504236fdd9460973fe10aadc570c826';
export default node;
