import React, { FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { styled, Button, CircularProgress } from "@mui/material";
import { ReportHeader_shop$key } from "__relay_artifacts__/ReportHeader_shop.graphql";
import Util from "lib/Util";
import { useMutation } from "relay-hooks";
import { ReportHeader_CreateTransferMutation } from "__relay_artifacts__/ReportHeader_CreateTransferMutation.graphql";
import { useSnackbar } from 'notistack';

const fragment = graphql`
  fragment ReportHeader_shop on Shop {
    id
    amountForTransfer
  }
`

const mutation = graphql`
  mutation ReportHeader_CreateTransferMutation($input: CreateTransferInput!) {
    createTransfer(input: $input) {
      shop {
        ...ReportHeader_shop
      }
    }
  }
`

const Wrapper = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "4px",
  width: "100%",
  height: "250px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
})

const Text = styled("p")({
  fontSize: "15px",
  fontWeight: "bold"
})

const Amount = styled("p")({
  fontSize: "40px",
  fontWeight: "bold",
  margin: "10px 0px 25px"
})

const Caution = styled("p")({
  fontSize: "12px",
  color: "#4e4e4e",
  margin: "0px 0px 5px"
})


const StyledButton = styled(Button)({
  backgroundColor: "#000",
  color: "#fff",
  fontSize: "12px",
  fontWeight: "bold",
  borderRadius: "4px",
  width: "150px",
  height: "40px",
  "&:hover": {
    backgroundColor: "#000",
  }
})

type Props = {
  shop: ReportHeader_shop$key
}

const ReportHeader: FC<Props> = (props) => {
  const shop = useFragment(fragment, props.shop);
  const [mutate] = useMutation<ReportHeader_CreateTransferMutation>(mutation);
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    setIsLoading(true)

    mutate({
      variables: {
        input: {
          amount: shop.amountForTransfer
        }
      },
      onCompleted: (data) => {
        enqueueSnackbar("入金申請が完了しました", {
          variant: "success"
        })
        setIsLoading(false)
      },
      onError: (errors) => {
        if (errors?.length > 0) {
          enqueueSnackbar(errors[0].message, {
            variant: "error"
          })
        }

        setIsLoading(false)
      }
    })
  }

  return (
    <>
      <Wrapper>
        {
          shop.amountForTransfer === 0 && <>
            <Text>お振り込み可能な売上がございません</Text>
          </>
        }

        {
          shop.amountForTransfer > 0 && <>
            <div style={{ textAlign: "center" }}>
              <p style={{ marginBottom: "0px" }}>入金可能額</p>
              <Amount>{Util.numberToCurrency(shop.amountForTransfer)}</Amount>

              <StyledButton onClick={handleClick}>
                {
                  isLoading
                    ?
                    <CircularProgress size={20} />
                    :
                    <span>入金申請</span>
                }
              </StyledButton>
            </div>
          </>
        }
      </Wrapper>

      <div style={{ marginTop: "20px" }}>
        <Caution>※ 発送完了から2週間経過したオーダーが入金可能額として計上されます</Caution>
        <Caution>※ 上記金額から、振り込み手数料250円を差し引いた金額が振り込まれます</Caution>
      </div>
    </>
  )
}

export default ReportHeader;