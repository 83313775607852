/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditExtraShippingForm_shop = {
    readonly id: string;
    readonly extraShipping: {
        readonly id: string;
        readonly fee: number;
        readonly enabled: boolean;
    } | null;
    readonly " $refType": "EditExtraShippingForm_shop";
};
export type EditExtraShippingForm_shop$data = EditExtraShippingForm_shop;
export type EditExtraShippingForm_shop$key = {
    readonly " $data"?: EditExtraShippingForm_shop$data;
    readonly " $fragmentRefs": FragmentRefs<"EditExtraShippingForm_shop">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditExtraShippingForm_shop",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ShopExtraShipping",
      "kind": "LinkedField",
      "name": "extraShipping",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fee",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Shop",
  "abstractKey": null
};
})();
(node as any).hash = '6dcf463efd34cbaf35f474113c754ddd';
export default node;
