import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import { useQuery, gql } from '@apollo/client';
import { useParams } from "react-router-dom";
import OrderShowScreen, { OrderShowScreenFragment } from "components/OrderShowScreen"
import { OrderShowQuery } from "__generated__/api";

const query = gql`
  query OrderShowQuery($id: ID!) {
    ...OrderShowScreen_query
  }
  ${OrderShowScreenFragment}
`

type Props = {}
const OrderShow: FC<Props> = (props) => {
  const variables = { id: useParams().id };
  const { data } = useQuery<OrderShowQuery>(query, {
    variables
  });
  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <OrderShowScreen query={data} />
      </Container>
    </>
  )
}

export default OrderShow;