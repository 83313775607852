/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountEmailScreen_query = {
    readonly viewer: {
        readonly id: string;
        readonly shop: {
            readonly " $fragmentRefs": FragmentRefs<"EditEmailForm_shop">;
        };
    } | null;
    readonly " $refType": "AccountEmailScreen_query";
};
export type AccountEmailScreen_query$data = AccountEmailScreen_query;
export type AccountEmailScreen_query$key = {
    readonly " $data"?: AccountEmailScreen_query$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountEmailScreen_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountEmailScreen_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Shop",
          "kind": "LinkedField",
          "name": "shop",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EditEmailForm_shop"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '10eb758ed649bf9da0b59a4928a7c61a';
export default node;
