/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type CreateLiveInput = {
    clientMutationId?: string | null;
    name: string;
    startAt: string;
    imageData: string;
    categoryId: string;
};
export type LiveForm_CreateLiveMutationVariables = {
    input: CreateLiveInput;
};
export type LiveForm_CreateLiveMutationResponse = {
    readonly createLive: {
        readonly live: {
            readonly id: string;
        };
    } | null;
};
export type LiveForm_CreateLiveMutation = {
    readonly response: LiveForm_CreateLiveMutationResponse;
    readonly variables: LiveForm_CreateLiveMutationVariables;
};



/*
mutation LiveForm_CreateLiveMutation(
  $input: CreateLiveInput!
) {
  createLive(input: $input) {
    live {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateLivePayload",
    "kind": "LinkedField",
    "name": "createLive",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Live",
        "kind": "LinkedField",
        "name": "live",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveForm_CreateLiveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LiveForm_CreateLiveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6ef3881302be55a2fb6106cab63af636",
    "id": null,
    "metadata": {},
    "name": "LiveForm_CreateLiveMutation",
    "operationKind": "mutation",
    "text": "mutation LiveForm_CreateLiveMutation(\n  $input: CreateLiveInput!\n) {\n  createLive(input: $input) {\n    live {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'c5a859244cbf2292b0d843f982b41a06';
export default node;
