/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ItemsQueryVariables = {};
export type ItemsQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"ItemsScreen_query">;
};
export type ItemsQuery = {
    readonly response: ItemsQueryResponse;
    readonly variables: ItemsQueryVariables;
};



/*
query ItemsQuery {
  ...ItemsScreen_query
}

fragment ItemListItem_item on Item {
  id
  name
}

fragment ItemList_shop on Shop {
  items(onlyShown: false) {
    edges {
      node {
        ...ItemListItem_item
        id
      }
    }
  }
}

fragment ItemsScreen_query on Query {
  viewer {
    id
    shop {
      ...ItemList_shop
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ItemsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ItemsScreen_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ItemsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "onlyShown",
                    "value": false
                  }
                ],
                "concreteType": "ItemConnection",
                "kind": "LinkedField",
                "name": "items",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ItemEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Item",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "items(onlyShown:false)"
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc0b3ae25160a2981671f27190a61dbf",
    "id": null,
    "metadata": {},
    "name": "ItemsQuery",
    "operationKind": "query",
    "text": "query ItemsQuery {\n  ...ItemsScreen_query\n}\n\nfragment ItemListItem_item on Item {\n  id\n  name\n}\n\nfragment ItemList_shop on Shop {\n  items(onlyShown: false) {\n    edges {\n      node {\n        ...ItemListItem_item\n        id\n      }\n    }\n  }\n}\n\nfragment ItemsScreen_query on Query {\n  viewer {\n    id\n    shop {\n      ...ItemList_shop\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bb0a097278a69ff3104ba37a514ed430';
export default node;
