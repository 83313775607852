/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountBankScreen_query = {
    readonly viewer: {
        readonly " $fragmentRefs": FragmentRefs<"EditBankForm_user">;
    } | null;
    readonly " $refType": "AccountBankScreen_query";
};
export type AccountBankScreen_query$data = AccountBankScreen_query;
export type AccountBankScreen_query$key = {
    readonly " $data"?: AccountBankScreen_query$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountBankScreen_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountBankScreen_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditBankForm_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = 'c057978b65df96978e6d9f138112dbba';
export default node;
