import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "relay-hooks";
import { EditBankForm_user$key } from "__relay_artifacts__/EditBankForm_user.graphql";
import yup from "lib/yup-extended"
import { TextField, Button, styled, CircularProgress } from '@mui/material';
import { ErrorMessage, Formik, Form } from "formik";
import BankAutoCompleteForm from "./BankAutoCompleteForm";
import BranchAutoCompleteForm from "./BranchAutoCompleteForm";
import AccountKindSelect from "./AccountKindSelect";
import { EditBankForm_UpdateAccountMutation } from "__relay_artifacts__/EditBankForm_UpdateAccountMutation.graphql";
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack';
import StyledErrorMessage from "components/Common/StyledErrorMessage";

const schema = yup.object().shape({
  bank: yup.object().nullable().required("銀行を選択してください"),
  branch: yup.object().nullable().required("支店を選択してください"),
  number: yup.number().required("口座番号を入力してください"),
  // @ts-ignore
  name: yup.string().required("口座名をカタカナで入力してください").katakana()
});

const mutation = graphql`
  mutation EditBankForm_UpdateAccountMutation($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      account {
        id
      }
    }
  }
`

const fragment = graphql`
  fragment EditBankForm_user on User {
    id

    account {
      bank {
        id
        name
      }

      branch {
        id
        name
      }

      name
      number
      kind
    }
  }
`

const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: "6px",
  height: "55px",
  width: "100%",
  color: "#fff",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
}));

const ButtonWrapper = styled("div")({
  margin: "30px 0px"
});

const StyledFormikTextField = styled(TextField)({
  marginTop: 0,
  background: "#fff",
  minHeight: '47px',
});

const Name = styled("p")({
  marginBottom: "15px",
  fontSize: "14px"
})

type Props = {
  user: EditBankForm_user$key
}

const EditBankForm: FC<Props> = (props) => {
  const user = useFragment(fragment, props.user);
  const [mutate] = useMutation<EditBankForm_UpdateAccountMutation>(mutation)
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values) => {
    mutate({
      variables: {
        input: {
          bankId: values.bank.id,
          branchId: values.branch.id,
          name: values.name,
          number: String(values.number),
          kind: values.kind
        }
      },
      onCompleted: (data) => {
        enqueueSnackbar("口座情報の設定に成功しました", {
          variant: "success"
        })

        history.push("/account");
      }
    })
  }

  return (
    <>
      <Formik
        initialValues={{
          bank: user.account?.bank || null,
          branch: user.account?.branch || null,
          number: user.account?.number || "",
          name: user.account?.name || "",
          kind: user.account?.kind || "REGULAR"
        }}
        validateOnBlur={true}
        validateOnChange={true}
        validationSchema={schema}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          return (
             <Form>
               <Name>銀行名</Name>
               <BankAutoCompleteForm />

               <Name>支店名</Name>
               <BranchAutoCompleteForm />

               <Name>口座名</Name>
               <StyledFormikTextField
                 name="number"
                 fullWidth={true}
                 value={values.number}
                 placeholder="口座番号(7桁)を記入"
                 margin="normal"
                 variant="outlined"
                 InputProps={{
                   inputProps: { maxLength: 7 },
                 }}
                 onChange={(event) => {
                   const number = parseInt(event.target.value);
                   setFieldValue("number", isNaN(number) ? "" : number);
                 }}
               />
              <ErrorMessage name="number" component={StyledErrorMessage} />

              <AccountKindSelect />

              <Name>口座名義(カナ)</Name>

              <StyledFormikTextField
                name="name"
                value={values.name}
                fullWidth={true}
                placeholder="口座名義(カナ)を記入"
                margin="normal"
                variant="outlined"
                onChange={(event) => {

                  setFieldValue("name", event.target.value)

                }}
              />
              <ErrorMessage name="name" component={StyledErrorMessage} />

              <ButtonWrapper>
                <SaveButton type="submit" disabled={isSubmitting}>
                  {
                    isSubmitting
                      ? <CircularProgress size={20} />
                      : "保存する"
                  }
                </SaveButton>
              </ButtonWrapper>
            </Form>
          )
        }}
      </Formik>
    </>
  )

}

export default EditBankForm;