import React, { FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "relay-hooks";
import { LiveShowScreen_shop$key } from "__relay_artifacts__/LiveShowScreen_shop.graphql";
import { styled, Button } from "@mui/material";
import LiveStatusText from "components/Common/LiveStatusText";
import format from "date-fns/format";
import ja from "date-fns/locale/ja";
import ConfirmDialog from "components/Common/ConfirmDialog";
import { useSnackbar } from 'notistack';
import { LiveShowScreen_DeleteLiveMutation } from "__relay_artifacts__/LiveShowScreen_DeleteLiveMutation.graphql";
import { useHistory } from 'react-router-dom'
import Switch from '@mui/material/Switch';
import { LiveShowScreen_UpdateLiveShownMutation } from "__relay_artifacts__/LiveShowScreen_UpdateLiveShownMutation.graphql";
import { Link } from "react-router-dom";

const fragment = graphql`
  fragment LiveShowScreen_shop on Shop {
    live(id: $id) {
      id
      status
      shown
      startAt

      ...LiveStatusText_live
    }
  }
`

const mutation = graphql`
  mutation LiveShowScreen_DeleteLiveMutation($input: DeleteLiveInput!) {
    deleteLive(input: $input) {
      deletedLiveId
    }
  }
`

const shownMutation = graphql`
  mutation LiveShowScreen_UpdateLiveShownMutation($input: UpdateLiveShownInput!) {
    updateLiveShown(input: $input) {
      live {
        id
        shown
      }
    }
  }
`

const StyledButton = styled(Button)({
  backgroundColor: "#ff2300",
  color: "#fff",
  fontSize: '12px',
  padding: "8px 14px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#ff2300",
  },
})

const FlexWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #ECECEC",
  padding: "10px 0px"
})

type Props = {
  shop: LiveShowScreen_shop$key
}

const LiveShow: FC<Props> = (props) => {
  const history = useHistory();
  const { live } = useFragment(fragment, props.shop);
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [mutate] = useMutation<LiveShowScreen_DeleteLiveMutation>(mutation);
  const [shownMutate] = useMutation<LiveShowScreen_UpdateLiveShownMutation>(shownMutation);

  const onYes = async () => {
    await mutate({
      variables: {
        input: {
          liveId: live.id
        }
      },
      onCompleted: (res) => {
        enqueueSnackbar("ライブの削除の成功しました", {
          variant: "success"
        })

        history.push("/");
      },
      onError: (errors) => {
        if (errors?.length > 0) {
          enqueueSnackbar(errors[0].message, {
            variant: "error"
          })
        }
      }
    });
  }

  const handleChange = async () => {
    await shownMutate({
      variables: {
        input: {
          liveId: live.id,
          shown: !live.shown
        }
      },
      onComplete: (res) => {
        enqueueSnackbar("公開ステータスを更新しました", {
          variant: "success"
        })
      }
    })

  }

  return (
    <>
      <div style={{ margin: "30px 0px" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <h3>ライブ詳細</h3>

          {/* <Link to={`/lives/${live.id}/auctions`}>
            <StyledButton>
              <span>オークション登録へ</span>
            </StyledButton>
          </Link> */}
        </div>

        <div style={{ margin: "20px 0px" }}>
          <FlexWrapper>
            <p>ステータス</p>
            <LiveStatusText live={live} />
          </FlexWrapper>

          <FlexWrapper>
            <p>公開ステータス</p>
            <Switch
              checked={live.shown}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </FlexWrapper>

          <FlexWrapper>
            <p>開催日時</p>
            <p style={{ fontSize: "14px" }}>{format(new Date(live.startAt), "yyyy/MM/d HH:mm", { locale: ja })}</p>
          </FlexWrapper>
        </div>

        <div style={{ textAlign: 'center', margin: "50px 0px" }}>
          <Button
            onClick={() => { setOpen(true) }}
            style={{
              padding: "10px 50px",
              backgroundColor: "#eeeeee",
              fontSize: "13px",
              color: "#4d4d4d"
            }}
          >
            削除する
          </Button>
        </div>
      </div>

      <ConfirmDialog
        open={open}
        doYes={onYes}
        doNo={() => { setOpen(false) }}
        message={"本当に削除してもよろしいですか？"}
        yesText={"削除する"}
        noText={"キャンセル"}
      />
    </>
  )

}

export default LiveShow;