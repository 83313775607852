/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateShopEmailInput = {
    clientMutationId?: string | null;
    password: string;
    email: string;
};
export type PasswordConfirmDialog_UpdateShopEmailMutationVariables = {
    input: UpdateShopEmailInput;
};
export type PasswordConfirmDialog_UpdateShopEmailMutationResponse = {
    readonly updateShopEmail: {
        readonly shop: {
            readonly id: string;
            readonly email: string | null;
        };
    } | null;
};
export type PasswordConfirmDialog_UpdateShopEmailMutation = {
    readonly response: PasswordConfirmDialog_UpdateShopEmailMutationResponse;
    readonly variables: PasswordConfirmDialog_UpdateShopEmailMutationVariables;
};



/*
mutation PasswordConfirmDialog_UpdateShopEmailMutation(
  $input: UpdateShopEmailInput!
) {
  updateShopEmail(input: $input) {
    shop {
      id
      email
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateShopEmailPayload",
    "kind": "LinkedField",
    "name": "updateShopEmail",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Shop",
        "kind": "LinkedField",
        "name": "shop",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "email",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PasswordConfirmDialog_UpdateShopEmailMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PasswordConfirmDialog_UpdateShopEmailMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "314655489450c3f35854a75bc918573a",
    "id": null,
    "metadata": {},
    "name": "PasswordConfirmDialog_UpdateShopEmailMutation",
    "operationKind": "mutation",
    "text": "mutation PasswordConfirmDialog_UpdateShopEmailMutation(\n  $input: UpdateShopEmailInput!\n) {\n  updateShopEmail(input: $input) {\n    shop {\n      id\n      email\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bce9a9c750a88265605b3c2c94265518';
export default node;
