/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TransferStatus = "COMPLETED" | "FAILED" | "PENDING" | "%future added value";
export type TransferStatusLabel_transfer = {
    readonly status: TransferStatus;
    readonly " $refType": "TransferStatusLabel_transfer";
};
export type TransferStatusLabel_transfer$data = TransferStatusLabel_transfer;
export type TransferStatusLabel_transfer$key = {
    readonly " $data"?: TransferStatusLabel_transfer$data;
    readonly " $fragmentRefs": FragmentRefs<"TransferStatusLabel_transfer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransferStatusLabel_transfer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Transfer",
  "abstractKey": null
};
(node as any).hash = '4d466214db6e4ea0810031b86f37b9ed';
export default node;
