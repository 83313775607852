/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditShippingForm_query = {
    readonly " $fragmentRefs": FragmentRefs<"PrefectureSelect_query">;
    readonly " $refType": "EditShippingForm_query";
};
export type EditShippingForm_query$data = EditShippingForm_query;
export type EditShippingForm_query$key = {
    readonly " $data"?: EditShippingForm_query$data;
    readonly " $fragmentRefs": FragmentRefs<"EditShippingForm_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditShippingForm_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PrefectureSelect_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = 'aab66d4fe4d411127d269059adea4bb5';
export default node;
