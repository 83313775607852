/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AuctionsQueryVariables = {
    id: string;
    first: number;
    after?: string | null;
};
export type AuctionsQueryResponse = {
    readonly viewer: {
        readonly shop: {
            readonly " $fragmentRefs": FragmentRefs<"AuctionsScreen_shop">;
        };
    } | null;
};
export type AuctionsQuery = {
    readonly response: AuctionsQueryResponse;
    readonly variables: AuctionsQueryVariables;
};



/*
query AuctionsQuery(
  $id: ID!
  $first: Int!
  $after: String
) {
  viewer {
    shop {
      ...AuctionsScreen_shop
      id
    }
    id
  }
}

fragment AuctionForm_live on Live {
  id
}

fragment AuctionListItem_auction on Auction {
  id
  name
  startPrice
  status
  ...AuctionStatusText_auction
}

fragment AuctionListItem_live on Live {
  id
}

fragment AuctionList_live on Live {
  ...AuctionListItem_live
  auctions(first: $first, after: $after) {
    edges {
      node {
        ...AuctionListItem_auction
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment AuctionStatusText_auction on Auction {
  id
  status
}

fragment AuctionsScreen_shop on Shop {
  live(id: $id) {
    id
    ...AuctionForm_live
    ...AuctionList_live
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AuctionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AuctionsScreen_shop"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AuctionsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "id"
                  }
                ],
                "concreteType": "Live",
                "kind": "LinkedField",
                "name": "live",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "concreteType": "AuctionConnection",
                    "kind": "LinkedField",
                    "name": "auctions",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AuctionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Auction",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "startPrice",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "status",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v4/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "AuctionList_auctions",
                    "kind": "LinkedHandle",
                    "name": "auctions"
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "761f4fcffe681eca1bfd9d24d7bfa77f",
    "id": null,
    "metadata": {},
    "name": "AuctionsQuery",
    "operationKind": "query",
    "text": "query AuctionsQuery(\n  $id: ID!\n  $first: Int!\n  $after: String\n) {\n  viewer {\n    shop {\n      ...AuctionsScreen_shop\n      id\n    }\n    id\n  }\n}\n\nfragment AuctionForm_live on Live {\n  id\n}\n\nfragment AuctionListItem_auction on Auction {\n  id\n  name\n  startPrice\n  status\n  ...AuctionStatusText_auction\n}\n\nfragment AuctionListItem_live on Live {\n  id\n}\n\nfragment AuctionList_live on Live {\n  ...AuctionListItem_live\n  auctions(first: $first, after: $after) {\n    edges {\n      node {\n        ...AuctionListItem_auction\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment AuctionStatusText_auction on Auction {\n  id\n  status\n}\n\nfragment AuctionsScreen_shop on Shop {\n  live(id: $id) {\n    id\n    ...AuctionForm_live\n    ...AuctionList_live\n  }\n}\n"
  }
};
})();
(node as any).hash = '855033fdfd5190824050fa251ee56e98';
export default node;
