import React, { FC, useMemo, useState } from "react";
import { styled, Button, CircularProgress } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "relay-hooks";
import { LiveForm_query$key } from "__relay_artifacts__/LiveForm_query.graphql"
import { LiveForm_CreateLiveMutation } from "__relay_artifacts__/LiveForm_CreateLiveMutation.graphql";
import StyledErrorMessage from "components/Common/StyledErrorMessage"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale } from "react-datepicker";
import ja from 'date-fns/locale/ja';
import ImageForm from "./ImageForm";
registerLocale('ja', ja)

const schema = Yup.object({
  name: Yup.string().required("ライブ名を入力してください").max(25, "25文字以内で入力してください"),
  category: Yup.string().required("カテゴリを選択してください"),
  imageData: Yup.string().required("画像を選択してください"),
  startAt: Yup.string().nullable().required("開始時間を選択してください")
});

const mutation = graphql`
  mutation LiveForm_CreateLiveMutation($input: CreateLiveInput!) {
    createLive(input: $input) {
      live {
        id
      }
    }
  }
`

const fragment = graphql`
  fragment LiveForm_query on Query {
    categories {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

const Wrapper = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "4px",
  width: "100%",
  marginBottom: "50px"
})

const FieldWrapper = styled("div")({
  marginBottom: "30px"
})

const StyledField = styled(Field)({
  height: "45px",
  width: "100%",
  fontSize: "14px",
  borderRadius: "4px",
  padding: "0px 18px",
  border: "solid 1px #666666"
});

const FieldTitle = styled("p")({
  fontSize: "15px",
  fontWeight: "bold"
})

const ButtonFlexWrapper = styled("div")({
  display: "flex",
  justifyContent: "center"
})

const StyledButton = styled(Button)({
  backgroundColor: "#000",
  borderRadius: "4px",
  padding: "15px 120px",
  fontSize: "13px",
  fontWeight: "bold",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
  },
})


type Props = {
  query: LiveForm_query$key
}

const LiveForm: FC<Props> = (props) => {
  const query = useFragment(fragment, props.query);
  const [mutate] = useMutation<LiveForm_CreateLiveMutation>(mutation);

  const onSubmit = async (values) => {
    await mutate({
      variables: {
        input: {
          imageData: values.imageData,
          startAt: values.startAt.toString(),
          categoryId: values.category,
          name: values.name
        }
      },
      onCompleted: (res) => {
        window.location.href = "/"
      },
      onError: (error) => {
      }
    })
  }

  const categories = useMemo(() => {
    return query.categories.edges.map((edge) => {
      const node = edge.node!
      return { id: node.id, name: node.name }
    })
  }, [query.categories])

  const addDays = (date, number) => {
    return new Date(date.setDate(date.getDate() + number));
  }

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          category: "",
          imageData: "",
          startAt: null
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
      >
        {(formik) => {
          return (
            <Form>
              <Wrapper>
                <FieldWrapper>
                  <FieldTitle>ライブ名</FieldTitle>
                  <StyledField type="text" name="name" />
                  <ErrorMessage name="name" component={StyledErrorMessage} />
                </FieldWrapper>

                <FieldWrapper>
                  <FieldTitle>開始日時</FieldTitle>
                  <DatePicker
                    locale="ja"
                    selected={formik.values.startAt}
                    onChange={(dateTime) => formik.setFieldValue("startAt", dateTime)}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={30}
                    minDate={new Date()}
                    maxDate={addDays(new Date(), 10)}
                    timeCaption="time"
                    dateFormat="yyyy年MMMMd日 h:mm aa"
                    wrapperClassName="datepicker"
                  />
                  <ErrorMessage name="startAt" component={StyledErrorMessage} />
                </FieldWrapper>

                <FieldWrapper>
                  <FieldTitle>カテゴリ</FieldTitle>
                  <Field as="select" name="category" style={{ height: "45px", width: "100%", border: "1px solid #666666", borderRadius: "4px", padding: "0px 20px" }} >
                    <option value="">選択してください</option>
                    {
                      categories.map((node, i) => {
                        return <option key={i} value={node.id}>{node.name}</option>;
                      })
                    }
                  </Field>
                  <ErrorMessage name="category" component={StyledErrorMessage} />
                </FieldWrapper>

                <FieldWrapper>
                  <FieldTitle>画像</FieldTitle>
                  <ImageForm />
                  <ErrorMessage name="imageData" component={StyledErrorMessage} />
                </FieldWrapper>

              </Wrapper>

              <ButtonFlexWrapper>
                <StyledButton type={"submit"}>
                  {
                    formik.isSubmitting
                      ? <CircularProgress size={20} />
                      : "保存する"
                  }
                </StyledButton>
              </ButtonFlexWrapper>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default LiveForm;