import React, { FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { Link } from "react-router-dom";
import { styled, ListItem  } from "@mui/material";
import Util from "lib/Util";
import format from "date-fns/format";
import ja from "date-fns/locale/ja";
import { TransferListItem_transfer$key } from "__relay_artifacts__/TransferListItem_transfer.graphql";
import TransferStatusLabel from "./TransferStatusLabel"

const fragment = graphql`
  fragment TransferListItem_transfer on Transfer {
    id
    amount
    createdAt

    ...TransferStatusLabel_transfer
  }
`

const StyledListItem = styled(ListItem)({
  borderRadius: "8px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
  padding: "16px 16px",
});

const FlexFirst = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 500px)"
})

const FlexEnd = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "190px",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-end",
  },
}));

const Status = styled("span")(({ theme }) => ({
  width: "110px",
  minWidth: "110px",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px",
  },
}));

const Price = styled("span")(({ theme }) => ({
  width: "110px",
  minWidth: "110px",
  fontSize: "13px",
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px",
  },
}));

const CreatedAt = styled("span")(({ theme }) => ({
  justifyContent: "flex-end",
  display: "flex",
  flex: "1",
  fontSize: "13px",
}));

type Props = {
  transfer: TransferListItem_transfer$key
}

const TransferListItem: FC<Props> = (props) => {
  const transfer = useFragment(fragment, props.transfer);

  return (
    <StyledListItem button={false as any}>
      <FlexFirst>
        <Status>
          <TransferStatusLabel transfer={transfer} />
        </Status>
        <Price>{Util.numberToCurrency(transfer.amount)}</Price>
      </FlexFirst>

      <FlexEnd>
        <CreatedAt>{format(new Date(transfer.createdAt), "Y/M/d HH:mm", { locale: ja })}</CreatedAt>
      </FlexEnd>
    </StyledListItem>
  )

}

export default TransferListItem;