/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type CreateAuctionInput = {
    clientMutationId?: string | null;
    liveId: string;
    name: string;
    startPrice: number;
    numbering?: boolean | null;
    count?: number | null;
};
export type AuctionForm_CreateAuctionMutationVariables = {
    input: CreateAuctionInput;
};
export type AuctionForm_CreateAuctionMutationResponse = {
    readonly createAuction: {
        readonly auctionEdge: {
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"AuctionListItem_auction">;
            } | null;
        };
    } | null;
};
export type AuctionForm_CreateAuctionMutation = {
    readonly response: AuctionForm_CreateAuctionMutationResponse;
    readonly variables: AuctionForm_CreateAuctionMutationVariables;
};



/*
mutation AuctionForm_CreateAuctionMutation(
  $input: CreateAuctionInput!
) {
  createAuction(input: $input) {
    auctionEdge {
      node {
        ...AuctionListItem_auction
        id
      }
    }
  }
}

fragment AuctionListItem_auction on Auction {
  id
  name
  startPrice
  status
  ...AuctionStatusText_auction
}

fragment AuctionStatusText_auction on Auction {
  id
  status
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AuctionForm_CreateAuctionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAuctionPayload",
        "kind": "LinkedField",
        "name": "createAuction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuctionEdge",
            "kind": "LinkedField",
            "name": "auctionEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Auction",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "AuctionListItem_auction"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AuctionForm_CreateAuctionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAuctionPayload",
        "kind": "LinkedField",
        "name": "createAuction",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuctionEdge",
            "kind": "LinkedField",
            "name": "auctionEdge",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Auction",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "startPrice",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "59db0624188d4c1f9d08bdd5f86e4e08",
    "id": null,
    "metadata": {},
    "name": "AuctionForm_CreateAuctionMutation",
    "operationKind": "mutation",
    "text": "mutation AuctionForm_CreateAuctionMutation(\n  $input: CreateAuctionInput!\n) {\n  createAuction(input: $input) {\n    auctionEdge {\n      node {\n        ...AuctionListItem_auction\n        id\n      }\n    }\n  }\n}\n\nfragment AuctionListItem_auction on Auction {\n  id\n  name\n  startPrice\n  status\n  ...AuctionStatusText_auction\n}\n\nfragment AuctionStatusText_auction on Auction {\n  id\n  status\n}\n"
  }
};
})();
(node as any).hash = 'fdf7d878415759a34268a83c6fb0fa03';
export default node;
