/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ReportScreen_query = {
    readonly viewer: {
        readonly id: string;
        readonly shop: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"ReportHeader_shop" | "TransferList_shop">;
        };
    } | null;
    readonly " $refType": "ReportScreen_query";
};
export type ReportScreen_query$data = ReportScreen_query;
export type ReportScreen_query$key = {
    readonly " $data"?: ReportScreen_query$data;
    readonly " $fragmentRefs": FragmentRefs<"ReportScreen_query">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportScreen_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Shop",
          "kind": "LinkedField",
          "name": "shop",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ReportHeader_shop"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TransferList_shop"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = 'ebf2e93d90942b6d15597239d61d92d6';
export default node;
