/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type PasswordMethod = "EMAIL" | "NUMBER" | "%future added value";
export type CreatePasswordInput = {
    clientMutationId?: string | null;
    number?: string | null;
    email?: string | null;
    method: PasswordMethod;
};
export type EditEmailForm_CreatePasswordMutationVariables = {
    input: CreatePasswordInput;
};
export type EditEmailForm_CreatePasswordMutationResponse = {
    readonly createPassword: {
        readonly password: {
            readonly id: string;
        } | null;
    } | null;
};
export type EditEmailForm_CreatePasswordMutation = {
    readonly response: EditEmailForm_CreatePasswordMutationResponse;
    readonly variables: EditEmailForm_CreatePasswordMutationVariables;
};



/*
mutation EditEmailForm_CreatePasswordMutation(
  $input: CreatePasswordInput!
) {
  createPassword(input: $input) {
    password {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreatePasswordPayload",
    "kind": "LinkedField",
    "name": "createPassword",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Password",
        "kind": "LinkedField",
        "name": "password",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditEmailForm_CreatePasswordMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditEmailForm_CreatePasswordMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "12990fcb24a28a7f99b970ca28d55331",
    "id": null,
    "metadata": {},
    "name": "EditEmailForm_CreatePasswordMutation",
    "operationKind": "mutation",
    "text": "mutation EditEmailForm_CreatePasswordMutation(\n  $input: CreatePasswordInput!\n) {\n  createPassword(input: $input) {\n    password {\n      id\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'bc2336b0721d2252da8aa22311dde812';
export default node;
