import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import AccountScreen from "components/AccountScreen";
import { AccountQuery } from "__relay_artifacts__/AccountQuery.graphql";

const query = graphql`
  query AccountQuery {
    ...AccountScreen_query
  }
`

type Props = {}
const Account: FC<Props> = () => {
  const { data } = useQuery<AccountQuery>(query);
  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <AccountScreen query={data} />
      </Container>
    </>
  )
}

export default Account