import React, { FC, useMemo } from "react"
import { graphql } from "babel-plugin-relay/macro";
import { LiveStatusText_live$key } from "__relay_artifacts__/LiveStatusText_live.graphql";
import { useFragment } from "relay-hooks";

const fragment = graphql`
  fragment LiveStatusText_live on Live {
    id
    status
  }
`

type Props = {
  live: LiveStatusText_live$key
}

const LiveStatusText: FC<Props> = (props) => {
  const live = useFragment(fragment, props.live);

  const statusText = useMemo(() => {
    switch (live.status) {
      case "PENDING":
        return "未開催";
      case "FINISHED":
        return "終了";
      case "ONAIR":
        return "開催中"
      default:
        return ""
    }
  }, [live.status])

  const backgroundColor = useMemo(() => {
    switch (live.status) {
      case "PENDING":
        return "#5B4DAE";
      case "FINISHED":
        return "#479679";
      case "ONAIR":
        return "#D85E5E"
      default:
        return ""
    }
  }, [live.status])

  return (
    <span style={{ backgroundColor: backgroundColor, padding: "5px 8px", fontSize: '11px', fontWeight: 'bold', borderRadius: "4px", color: "#fff" }}>
      {statusText}
    </span>
  )
}

export default LiveStatusText;
