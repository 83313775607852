import React, { FC } from "react";
import { useFragment } from "relay-hooks";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { styled, Button, CircularProgress } from "@mui/material";
import StyledErrorMessage from "components/Common/StyledErrorMessage";
import * as Yup from "yup";
import { EditShippingForm_shop$key } from "__relay_artifacts__/EditShippingForm_shop.graphql"
import { EditShippingForm_UpdateShopShippingMutation } from "__relay_artifacts__/EditShippingForm_UpdateShopShippingMutation.graphql";
import { EditShippingForm_query$key } from '__relay_artifacts__/EditShippingForm_query.graphql'
import PrefectureSelect from "./PrefectureSelect";
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack';
import { Close } from "@mui/icons-material";

const schema = Yup.object({
  fee: Yup.string().required("送料を入力してください"),
  detailedShippings: Yup.array()
  .of(
    Yup.object().shape({
      fee: Yup.number().nullable().required("送料を入力してください").min(100, "100円以上で入力してください")
    })
  )
});

const prefecturesFragment = graphql`
  fragment EditShippingForm_query on Query {
    ...PrefectureSelect_query
  }
`

const shopFragment = graphql`
  fragment EditShippingForm_shop on Shop {
    id

    shipping {
      id
      fee
    }

    detailedShippings {
      id
      fee

      prefecture {
        id
        name
      }
    }
  }
`

const mutation = graphql`
  mutation EditShippingForm_UpdateShopShippingMutation($input: UpdateShopShippingInput!) {
    updateShopShipping(input: $input) {
      shop {
        id

        ...EditShippingForm_shop
      }
    }
  }
`

const Wrapper = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "4px",
  padding: "35px 15px"
})

const StyledField = styled(Field)(({ theme }) => ({
  height: "50px",
  width: "120px",
  fontSize: "15px",
  borderRadius: "4px",
  outline: "none",
  padding: "0px 18px",
  border: "none",
  textAlign: 'right',
  backgroundColor: "#fafafa"
}));


const StyledButton = styled(Button)(({ theme }) => ({
  width: "150px",
  borderRadius: "4px",
  fontSize: "13px",
  backgroundColor: "#000",
  color: "#fff",
  fontWeight: "bold",
  padding: "12px 15px",
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
}));

const ListItem = styled("div")({
  position: 'relative',
  padding: "12px 20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #ddd",
  marginBottom: "18px",
  borderRadius: "4px"
})

const RemoveButton = styled("div")({
  backgroundColor: "#ddd",
  borderRadius: "30px",
  position: 'absolute',
  top: "-10px",
  right: "-10px",
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: "#fff"
})

const Name = styled("p")({
  margin: "0px",
  fontSize: "14px",
  fontWeight: "normal"
})

type Props = {
  shop: EditShippingForm_shop$key,
  query: EditShippingForm_query$key,
}

export type DetailedShipping = {
  fee: number,
  prefecture: {
    id: string,
    name: string,
  },
}

const EditShippingForm: FC<Props> = (props) => {
  const shop = useFragment(shopFragment, props.shop);
  const query = useFragment(prefecturesFragment, props.query);
  const [mutate] = useMutation<EditShippingForm_UpdateShopShippingMutation>(mutation);
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values) => {
    await mutate({
      variables: {
        input: {
          fee: parseInt(values.fee),
          detailedShippings: values.detailedShippings.filter((v) => v.fee !== '').map((v) => ({
            fee: parseInt(v.fee),
            prefectureId: v.prefecture.id,
          }))
        }
      },
      onCompleted: (res) => {
        enqueueSnackbar("送料の設定に成功しました", {
          variant: "success"
        })

        history.push("/account");
      },
      onError: (errors) => {
        if (errors?.length > 0) {
          enqueueSnackbar(errors[0].message, {
            variant: "error"
          })
        }
      }
    });
  }

  const detailedShippings: DetailedShipping[] = shop.detailedShippings.map((shipping) => {
    return {
      fee: shipping.fee,
      prefecture: {
        id: shipping.prefecture.id,
        name: shipping.prefecture.name
      }
    }
  })

  return (
    <>
      <Formik
        initialValues={{
          fee: shop.shipping?.fee ?? 0,
          detailedShippings,
        }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={schema}
      >
        {(formik) => {
          return (
            <Form>
              <Wrapper>
                <ListItem>
                  <Name>{formik.values.detailedShippings.length === 0 ? `全国地域一律` : `その他の地域`}</Name>
                  <div>
                    <StyledField type="text" name="fee" placeholder="100" />
                    <span style={{ marginLeft: "10px" }}>円</span>
                  </div>
                </ListItem>

                {
                  formik.values.detailedShippings.map((shipping, i) => {
                    return (
                      <ListItem key={i}>
                        <RemoveButton onClick={() => {
                          const shippings = formik.values.detailedShippings.filter((s) => s.prefecture.id !== shipping.prefecture.id)
                          formik.setFieldValue("detailedShippings", shippings)
                        }}>
                          <Close style={{ fontSize: "15px" }} />
                        </RemoveButton>
                        <Name>{shipping.prefecture.name}</Name>
                        <div style={{textAlign: "right"}}>
                          <StyledField type="text" name={`detailedShippings[${i}].fee`} placeholder="100" />
                          <span style={{ marginLeft: "10px" }}>円</span>
                          <ErrorMessage name={`detailedShippings[${i}].fee`} component={StyledErrorMessage} />
                        </div>
                      </ListItem>
                    )
                  })
                }

                <PrefectureSelect query={query} />
              </Wrapper>

              <div style={{ marginTop: "50px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ErrorMessage name="fee" component={StyledErrorMessage} />

                <StyledButton type="submit">
                  {
                    formik.isSubmitting
                      ? <CircularProgress size={20} />
                      : "保存する"
                  }
                </StyledButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>
  )

}

export default EditShippingForm;
