import React, { FC } from "react";
import { gql } from "@apollo/client";
import { styled } from "@mui/material";
import ShippingStatusLabel, { ShippingStatusLabelFragment } from "components/Common/ShippingStatusLabel"
import format from "date-fns/format";
import ja from "date-fns/locale/ja";
import OrderPaymentList, { OrderPaymentListFragment } from "../OrderPaymentList";
import Util from "lib/Util";
import ShippingStatusButton, { ShippingStatusButtonFragment } from "./ShippingStatusButton";
import { OrderDetail_order } from "__generated__/api";

export const OrderDetailFragment = gql`
  fragment OrderDetail_order on Order {
    id
    code
    amount
    shippingFee
    createdAt
    email

    address {
      id
      fullName
      addressText
    }

    shipping {
      id
      status
    }

    live {
      id
      name
    }

    ...OrderPaymentList_order
    ...ShippingStatusLabel_order
    ...ShippingStatusButton_order
  }
  ${OrderPaymentListFragment}
  ${ShippingStatusLabelFragment}
  ${ShippingStatusButtonFragment}
`

const RowTitle = styled("p")({
  fontSize: '14px',
  fontWeight: "bold",
  color: "#2E3C51",
  margin: "0px"
})

const RowContent = styled("p")({
  fontSize: "14px",
  textAlign: "end",
  margin: "0px"
})

const FlexWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "solid 1px #244d8a1a",
  padding: "30px 0px"
})

const Wrapper = styled("div")({
  borderRadius: "4px",
  backgroundColor: "#fff",
  padding: "20px 30px",
  marginBottom: "50px"
})

const FlexEnd = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "140px",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-end",
  },
}));

type Props = {
  order: OrderDetail_order
}

const OrderDetail: FC<Props> = (props) => {
  const { order } = props

  return (
    <>
      <Wrapper>
        <FlexWrapper>
          <RowTitle>ライブ名</RowTitle>
          <RowContent>{order.live.name}</RowContent>
        </FlexWrapper>

        <FlexWrapper>
          <RowTitle>発送ステータス</RowTitle>
          <RowContent><ShippingStatusLabel order={order} /></RowContent>
        </FlexWrapper>

        <FlexWrapper>
          <RowTitle>オーダー番号</RowTitle>
          <RowContent>{order.code}</RowContent>
        </FlexWrapper>

        <FlexWrapper>
          <RowTitle>購入日時</RowTitle>
          <RowContent>
            {format(new Date(order.createdAt), "Y/M/d HH:mm", { locale: ja })}
          </RowContent>
        </FlexWrapper>

        <FlexWrapper>
          <RowTitle>購入者名</RowTitle>
          <RowContent>{order.address.fullName}</RowContent>
        </FlexWrapper>

        <FlexWrapper>
          <RowTitle>お届け先住所</RowTitle>
          <RowContent>{order.address.addressText}</RowContent>
        </FlexWrapper>

        {/* <FlexWrapper>
          <RowTitle>メールアドレス</RowTitle>
          <RowContent>{order.email}</RowContent>
        </FlexWrapper> */}
      </Wrapper>

      <h3 style={{ marginBottom: "25px" }}>落札アイテム</h3>

      <Wrapper>
        <OrderPaymentList order={order} />

        <div style={{ marginTop: "40px" }}>
          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "15px" }}>
            <FlexEnd>
              <div style={{ fontSize: "14px", display: "flex", flex: "1", }} >
                送料
              </div>

              <div style={{ fontSize: "14px" }} >
                <p>{Util.numberToCurrency(order.shippingFee)}</p>
              </div>
            </FlexEnd>
          </div>

          <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "15px" }}>
            <FlexEnd>
              <div style={{ fontSize: "15px", fontWeight: "bold", display: "flex", flex: "1", }} >
                小計
              </div>

              <div style={{ fontSize: "15px", fontWeight: "bold" }} >
                <p>{Util.numberToCurrency(order.amount)}</p>
              </div>
            </FlexEnd>
          </div>
        </div>
      </Wrapper>

      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <ShippingStatusButton order={order} />
      </div>
    </>
  )
}

export default OrderDetail;