import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { styled, Dialog } from "@mui/material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { Button } from "@mui/material";
import { useMutation } from "relay-hooks";
import { AuctionForm_CreateAuctionMutation } from "__relay_artifacts__/AuctionForm_CreateAuctionMutation.graphql";
import { AuctionForm_live$key } from "__relay_artifacts__/AuctionForm_live.graphql";
import { useFragment } from "relay-hooks";
import * as Yup from "yup";
import StyledErrorMessage from "components/Common/StyledErrorMessage"
import { useFormik } from 'formik';

Yup.addMethod(Yup.number, 'priceUnit', function (unit: number, errorMessage: string) {
  return this.test('test-price-unit', errorMessage, (value) => value % unit === 0)
})

const schema = Yup.object({
  name: Yup.string().required("アイテム名を入力してください").max(20, "20文字以内で入力してください"),
  // @ts-ignore
  startPrice: Yup.number().required("開始金額を入力してください").min(100, '100円以上で入力してください').priceUnit(100, '100円単位で入力してください'),
});


const fragment = graphql`
  fragment AuctionForm_live on Live {
    id
  }
`

const mutation = graphql`
  mutation AuctionForm_CreateAuctionMutation($input: CreateAuctionInput!) {
    createAuction(input: $input) {
      auctionEdge {
        node {
          ...AuctionListItem_auction
        }
      }
    }
  }
`

const StyledLabel = styled("label")({
  marginBottom: "7px",
  fontWeight: "bold",
  fontSize: "14px",
  display: "block"
});

const StyledField = styled(Field)({
  height: "40px",
  width: "100%",
  fontSize: "14px",
  borderRadius: "4px",
  padding: "0px 18px",
  border: "solid 1px #666666"
});

const SubmitButton = styled(Button)({
  width: "100%",
  backgroundColor: "#000",
  borderRadius: "4px",
  height: "45px",
  fontSize: "13px",
  fontWeight: "bold",
  color: "#fff",
  "&:hover": {
    backgroundColor: "#000",
  },
})


type Props = {
  open: boolean
  handleClose: () => void
  live: AuctionForm_live$key
}

const AuctionForm: FC<Props> = (props) => {
  const { open, handleClose } = props;
  const live = useFragment(fragment, props.live);
  const [mutate] = useMutation<AuctionForm_CreateAuctionMutation>(mutation);

  const onSubmit = async (values, { resetForm }) => {
    await mutate({
      variables: {
        input: {
          liveId: live.id,
          name: values.name,
          startPrice: parseInt(values.startPrice)
        }
      },
      configs: [
        {
          type: "RANGE_ADD",
          parentID: live.id,
          connectionInfo: [
            { key: "AuctionList_auctions", rangeBehavior: "append" },
          ],
          edgeName: "auctionEdge",
        },
      ],
      onCompleted: (res) => {
        resetForm()
        handleClose()
      }
    });
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            padding: "20px 24px",
          }
        }}
        >

        <Formik
          initialValues={{
            name: "",
            startPrice: ""
          }}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={schema}
        >
          {(formik) => {
            return (
              <Form style={{padding: "15px"}}>
                <div style={{ marginBottom: "20px" }}>
                  <StyledLabel>アイテム名</StyledLabel>
                  <StyledField type="text" name="name" />
                  <ErrorMessage name="name" component={StyledErrorMessage} />
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <StyledLabel>開始金額</StyledLabel>
                  <StyledField type="text" name="startPrice" />
                  <ErrorMessage name="startPrice" component={StyledErrorMessage} />
                </div>

                <SubmitButton type="submit">
                  登録する
                </SubmitButton>
              </Form>
            )
          }}
        </Formik>
      </Dialog>
    </>

  )

}

export default AuctionForm;
