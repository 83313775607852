import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { ItemList_shop$key } from "__relay_artifacts__/ItemList_shop.graphql";
import { styled, List  } from "@mui/material";
import ItemListItem from "./ItemListItem";

const fragment = graphql`
  fragment ItemList_shop on Shop {
    items(onlyShown: false) {
      edges {
        node {
          ...ItemListItem_item
        }
      }
    }
  }
`

const HeaderRow = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "5px",
  padding: "0px 15px"
});

const NameHeader = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px"
  },
  [theme.breakpoints.up("md")]: {
    width: "220px",
  },
}));

const FlexFirst = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 200px)",
  [theme.breakpoints.up("md")]: {
    width: "calc(100% - 500px)",
  },
}));

type Props = {
  shop: ItemList_shop$key
}

const ItemList: FC<Props> = (props) => {
  const shop = useFragment(fragment, props.shop);

  return (
    <>
      <HeaderRow>
        <FlexFirst>
          <NameHeader>アイテム名</NameHeader>
        </FlexFirst>
      </HeaderRow>

      <List>
        {
          shop.items.edges.map((edge, i) => {
            const item = edge!.node!
            return (
              <ItemListItem item={item} key={i} />
            )
          })
        }
      </List>
    </>
  )
}

export default ItemList;
