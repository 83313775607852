/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type BankQueryVariables = {};
export type BankQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"AccountBankScreen_query">;
};
export type BankQuery = {
    readonly response: BankQueryResponse;
    readonly variables: BankQueryVariables;
};



/*
query BankQuery {
  ...AccountBankScreen_query
}

fragment AccountBankScreen_query on Query {
  viewer {
    ...EditBankForm_user
    id
  }
}

fragment EditBankForm_user on User {
  id
  account {
    bank {
      id
      name
    }
    branch {
      id
      name
    }
    name
    number
    kind
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "BankQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccountBankScreen_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "BankQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "UserAccount",
            "kind": "LinkedField",
            "name": "account",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Bank",
                "kind": "LinkedField",
                "name": "bank",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Branch",
                "kind": "LinkedField",
                "name": "branch",
                "plural": false,
                "selections": (v2/*: any*/),
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "kind",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d006c976b5d3dac9758218f4e6e590c8",
    "id": null,
    "metadata": {},
    "name": "BankQuery",
    "operationKind": "query",
    "text": "query BankQuery {\n  ...AccountBankScreen_query\n}\n\nfragment AccountBankScreen_query on Query {\n  viewer {\n    ...EditBankForm_user\n    id\n  }\n}\n\nfragment EditBankForm_user on User {\n  id\n  account {\n    bank {\n      id\n      name\n    }\n    branch {\n      id\n      name\n    }\n    name\n    number\n    kind\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'a837e633798900253543ba27f3b41420';
export default node;
