import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import { ReportQuery } from "__relay_artifacts__/ReportQuery.graphql";
import ReportScreen from "components/ReportScreen";

const query = graphql`
  query ReportQuery {
    ...ReportScreen_query
  }
`

type Props = {}

const Report: FC<Props> = () => {
  const { data } = useQuery<ReportQuery>(query, {first: 10});
  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <ReportScreen query={data} />
      </Container>
    </>
  )
}

export default Report;