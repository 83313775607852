import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { styled, List  } from "@mui/material";
import { TransferList_shop$key } from "__relay_artifacts__/TransferList_shop.graphql";
import TransferListItem from "./TransferListItem";

const fragment = graphql`
  fragment TransferList_shop on Shop {
    transfers {
      edges {
        node {
          ...TransferListItem_transfer
        }
      }
    }
  }
`

const HeaderRow = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "5px",
  padding: "0px 15px"
});

const StatusHeader = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px"
  },
  [theme.breakpoints.up("md")]: {
    width: "110px",
  },
}));

const PriceHeader = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
  display: "flex",
  flex: "1",
  [theme.breakpoints.down("sm")]: {
    flex: "none"
  },
}));

const LiveDate = styled("span")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "bold",
  color: "rgba(7,29,63,0.6)",
}));

const FlexFirst = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 200px)",
  [theme.breakpoints.up("md")]: {
    width: "calc(100% - 500px)",
  },
}));

const FlexEnd = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "190px",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-end",
  },
}));

type Props = {
  shop: TransferList_shop$key
}

const TransferList: FC<Props> = (props) => {
  const shop = useFragment(fragment, props.shop);

  return (
    <>
      <HeaderRow>
        <FlexFirst>
          <StatusHeader>ステータス</StatusHeader>
          <PriceHeader>金額</PriceHeader>
        </FlexFirst>

        <FlexEnd>
          <LiveDate>作成日時</LiveDate>
        </FlexEnd>
      </HeaderRow>

      <List>
        {
          shop.transfers.edges.map((edge, i) => {
            const transfer = edge.node!

            return (
              <TransferListItem transfer={transfer} key={i} />
            )
          })
        }
      </List>
    </>
  )
}

export default TransferList;