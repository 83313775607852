/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type TransferListItem_transfer = {
    readonly id: string;
    readonly amount: number;
    readonly createdAt: string;
    readonly " $fragmentRefs": FragmentRefs<"TransferStatusLabel_transfer">;
    readonly " $refType": "TransferListItem_transfer";
};
export type TransferListItem_transfer$data = TransferListItem_transfer;
export type TransferListItem_transfer$key = {
    readonly " $data"?: TransferListItem_transfer$data;
    readonly " $fragmentRefs": FragmentRefs<"TransferListItem_transfer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TransferListItem_transfer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TransferStatusLabel_transfer"
    }
  ],
  "type": "Transfer",
  "abstractKey": null
};
(node as any).hash = 'ff5bf72786019e40a4e39a54ae84cb65';
export default node;
