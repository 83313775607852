/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AuctionStatus = "CANCELED" | "DROPPED" | "FINISHED" | "PENDING" | "%future added value";
export type AuctionListItem_auction = {
    readonly id: string;
    readonly name: string;
    readonly startPrice: number;
    readonly status: AuctionStatus;
    readonly " $fragmentRefs": FragmentRefs<"AuctionStatusText_auction">;
    readonly " $refType": "AuctionListItem_auction";
};
export type AuctionListItem_auction$data = AuctionListItem_auction;
export type AuctionListItem_auction$key = {
    readonly " $data"?: AuctionListItem_auction$data;
    readonly " $fragmentRefs": FragmentRefs<"AuctionListItem_auction">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionListItem_auction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "startPrice",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AuctionStatusText_auction"
    }
  ],
  "type": "Auction",
  "abstractKey": null
};
(node as any).hash = '6fdb911803babc4cf4c4b6d0cb29e48b';
export default node;
