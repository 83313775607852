/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountShippingDetailedScreen_query = {
    readonly viewer: {
        readonly id: string;
        readonly shop: {
            readonly id: string;
            readonly " $fragmentRefs": FragmentRefs<"EditShippingForm_shop">;
        };
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"EditShippingForm_query">;
    readonly " $refType": "AccountShippingDetailedScreen_query";
};
export type AccountShippingDetailedScreen_query$data = AccountShippingDetailedScreen_query;
export type AccountShippingDetailedScreen_query$key = {
    readonly " $data"?: AccountShippingDetailedScreen_query$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountShippingDetailedScreen_query">;
};



const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountShippingDetailedScreen_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Shop",
          "kind": "LinkedField",
          "name": "shop",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "EditShippingForm_shop"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditShippingForm_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();
(node as any).hash = '7b2763cc8af1a015259bd03faae7e904';
export default node;
