import React, { FC } from "react";
import { Switch } from "react-router-dom"
import PrivateRouter from "components/PrivateRoute";
import Home from "pages/Home";
import Orders from "pages/Orders";
import LivesNew from "pages/Lives/LivesNew";
import LiveShow from "pages/LiveShow/index";
import Auctions from "pages/LiveShow/Auctions";
import OrderShow from "pages/OrderShow";
import Account from "pages/Account/index";
import AccountEmail from "pages/Account/Email";
import AccountShipping from "pages/Account/Shipping";
import AccountShippingDetailed from "pages/Account/Shipping/Detailed";
import AccountShippingExtra from "pages/Account/Shipping/Extra";
import AccountBank from "pages/Account/Bank";
import Report from "pages/Report"
import Items from 'pages/Items';

type Props = {}
const Router: FC<Props> = (props) => {
  return (
    <Switch>
      <PrivateRouter exact path="/" component={Home} redirectComponent={"Login"} />
      <PrivateRouter exact path="/items" component={Items} />
      <PrivateRouter exact path="/lives/new" component={LivesNew} />
      <PrivateRouter exact path="/lives/:id" component={LiveShow} />
      <PrivateRouter exact path="/lives/:id/auctions" component={Auctions} />
      <PrivateRouter exact path="/orders" component={Orders} />
      <PrivateRouter exact path="/orders/:id" component={OrderShow} />
      <PrivateRouter exact path="/account" component={Account} />
      <PrivateRouter exact path="/account/email" component={AccountEmail} />
      <PrivateRouter exact path="/account/shipping" component={AccountShipping} />
      <PrivateRouter exact path="/account/bank" component={AccountBank} />
      <PrivateRouter exact path="/account/shipping/detailed" component={AccountShippingDetailed} />
      <PrivateRouter exact path="/account/shipping/extra" component={AccountShippingExtra} />
      <PrivateRouter exact path="/report" component={Report} />
    </Switch>
  )
}

export default Router;
