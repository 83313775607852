import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { AccountShippingDetailedScreen_query$key } from "__relay_artifacts__/AccountShippingDetailedScreen_query.graphql";
import EditShippingForm from "./EditShippingForm";

const fragment = graphql`
  fragment AccountShippingDetailedScreen_query on Query {
    viewer {
      id

      shop {
        id
        ...EditShippingForm_shop
      }
    }

    ...EditShippingForm_query
  }
`

type Props = {
  query: AccountShippingDetailedScreen_query$key
}

const AccountShippingDetailedScreen: FC<Props> = (props) => {
  const query = useFragment(fragment, props.query);

  return (
    <>
      <div style={{ margin: "50px 0px" }}>
        <h3 style={{ marginBottom: "30px" }}>送料設定</h3>
        <EditShippingForm query={query} shop={query.viewer.shop} />
      </div>
    </>
  )

}

export default AccountShippingDetailedScreen;
