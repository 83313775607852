import React, { FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "relay-hooks";
import { AuctionListItem_auction$key } from "__relay_artifacts__/AuctionListItem_auction.graphql";
import { AuctionListItem_live$key } from "__relay_artifacts__/AuctionListItem_live.graphql";
import { styled } from "@mui/material";
import { AuctionListItem_DeleteAuctionMutation } from "__relay_artifacts__/AuctionListItem_DeleteAuctionMutation.graphql"
import { useSnackbar } from 'notistack';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmDialog from "components/Common/ConfirmDialog";
import AuctionStatusText from "components/Common/AuctionStatusText";

const fragments = {
  live: graphql`
    fragment AuctionListItem_live on Live {
      id
    }
  `,
  auction: graphql`
    fragment AuctionListItem_auction on Auction {
      id
      name
      startPrice
      status

      ...AuctionStatusText_auction
    }
  `
}

const mutation = graphql`
  mutation AuctionListItem_DeleteAuctionMutation($input: DeleteAuctionInput!) {
    deleteAuction(input: $input) {
      deletedAuctionId
    }
  }
`

const RowContent = styled("p")({
  fontSize: "14px"
})

const Name = styled("p")({
  fontSize: "14px",
  marginRight: "15px",
  fontWeight: "bold"
})

const Wrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#fafafa",
  borderRadius: "4px",
  padding: "5px 20px",
  marginBottom: "10px"
})

type Props = {
  auction: AuctionListItem_auction$key
  live: AuctionListItem_live$key
}

const AuctionListItem: FC<Props> = (props) => {
  const [open, setOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const [mutate] = useMutation<AuctionListItem_DeleteAuctionMutation>(mutation);
  const auction = useFragment(fragments.auction, props.auction);
  const live = useFragment(fragments.live, props.live);

  const onYes = async () => {
    await mutate({
      variables: {
        input: {
          auctionId: auction.id
        }
      },
      configs: [
        {
          type: "RANGE_DELETE",
          parentID: live.id,
          connectionKeys: [
            {
              key: "AuctionList_auctions",
            },
          ],
          pathToConnection: ["live", "auctions"],
          deletedIDFieldName: "deletedAuctionId",
        },
      ],
      onCompleted: (res) => {
        enqueueSnackbar("オークションの削除に成功しました", {
          variant: "success"
        })

        setOpen(false);
      },
      onError: (errors) => {
        if (errors?.length > 0) {
          enqueueSnackbar(errors[0].message, {
            variant: "error"
          })
        }
      }
    });
  }

  const handleClick = () => {
    setOpen(true)
  }

  return (
    <>
      <Wrapper>
        <div style={{display: "flex"}}>
          <Name>{auction.name}</Name>
          <RowContent>{auction.startPrice}</RowContent>
        </div>

        <div style={{display: "flex", alignItems: "center"}}>
          <RowContent>
            <AuctionStatusText auction={auction} />
            </RowContent>
          <DeleteIcon style={{ fontSize: "22px", marginLeft: "8px" }} onClick={handleClick} />
        </div>
      </Wrapper>

      <ConfirmDialog
        open={open}
        doYes={onYes}
        doNo={() => { setOpen(false) }}
        message={"本当に削除してもよろしいですか？"}
        yesText={"削除する"}
        noText={"キャンセル"}
      />
    </>
  )


}

export default AuctionListItem;