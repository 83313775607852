/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type UpdateLiveShownInput = {
    clientMutationId?: string | null;
    liveId: string;
    shown: boolean;
};
export type LiveShowScreen_UpdateLiveShownMutationVariables = {
    input: UpdateLiveShownInput;
};
export type LiveShowScreen_UpdateLiveShownMutationResponse = {
    readonly updateLiveShown: {
        readonly live: {
            readonly id: string;
            readonly shown: boolean;
        };
    } | null;
};
export type LiveShowScreen_UpdateLiveShownMutation = {
    readonly response: LiveShowScreen_UpdateLiveShownMutationResponse;
    readonly variables: LiveShowScreen_UpdateLiveShownMutationVariables;
};



/*
mutation LiveShowScreen_UpdateLiveShownMutation(
  $input: UpdateLiveShownInput!
) {
  updateLiveShown(input: $input) {
    live {
      id
      shown
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UpdateLiveShownPayload",
    "kind": "LinkedField",
    "name": "updateLiveShown",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Live",
        "kind": "LinkedField",
        "name": "live",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "shown",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveShowScreen_UpdateLiveShownMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LiveShowScreen_UpdateLiveShownMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7df354efff4a3d8c4a81ba674f6db448",
    "id": null,
    "metadata": {},
    "name": "LiveShowScreen_UpdateLiveShownMutation",
    "operationKind": "mutation",
    "text": "mutation LiveShowScreen_UpdateLiveShownMutation(\n  $input: UpdateLiveShownInput!\n) {\n  updateLiveShown(input: $input) {\n    live {\n      id\n      shown\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'e4bd8a8a0bba9424ce48db1306feefc5';
export default node;
