/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type NewLiveScreen_query = {
    readonly " $fragmentRefs": FragmentRefs<"LiveForm_query">;
    readonly " $refType": "NewLiveScreen_query";
};
export type NewLiveScreen_query$data = NewLiveScreen_query;
export type NewLiveScreen_query$key = {
    readonly " $data"?: NewLiveScreen_query$data;
    readonly " $fragmentRefs": FragmentRefs<"NewLiveScreen_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NewLiveScreen_query",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "LiveForm_query"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = '72d38764a2f0254bdc7e6a1b6a378c82';
export default node;
