/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AuctionListItem_live = {
    readonly id: string;
    readonly " $refType": "AuctionListItem_live";
};
export type AuctionListItem_live$data = AuctionListItem_live;
export type AuctionListItem_live$key = {
    readonly " $data"?: AuctionListItem_live$data;
    readonly " $fragmentRefs": FragmentRefs<"AuctionListItem_live">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionListItem_live",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Live",
  "abstractKey": null
};
(node as any).hash = '70d21a2cb8b1da59bcfa2da3225fd2b4';
export default node;
