/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type UpdateExtraShippingInput = {
    clientMutationId?: string | null;
    fee: number;
    enabled: boolean;
};
export type EditExtraShippingForm_UpdateExtraShippingMutationVariables = {
    input: UpdateExtraShippingInput;
};
export type EditExtraShippingForm_UpdateExtraShippingMutationResponse = {
    readonly updateExtraShipping: {
        readonly shop: {
            readonly " $fragmentRefs": FragmentRefs<"EditExtraShippingForm_shop">;
        };
    } | null;
};
export type EditExtraShippingForm_UpdateExtraShippingMutation = {
    readonly response: EditExtraShippingForm_UpdateExtraShippingMutationResponse;
    readonly variables: EditExtraShippingForm_UpdateExtraShippingMutationVariables;
};



/*
mutation EditExtraShippingForm_UpdateExtraShippingMutation(
  $input: UpdateExtraShippingInput!
) {
  updateExtraShipping(input: $input) {
    shop {
      ...EditExtraShippingForm_shop
      id
    }
  }
}

fragment EditExtraShippingForm_shop on Shop {
  id
  extraShipping {
    id
    fee
    enabled
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditExtraShippingForm_UpdateExtraShippingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExtraShippingPayload",
        "kind": "LinkedField",
        "name": "updateExtraShipping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EditExtraShippingForm_shop"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditExtraShippingForm_UpdateExtraShippingMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateExtraShippingPayload",
        "kind": "LinkedField",
        "name": "updateExtraShipping",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopExtraShipping",
                "kind": "LinkedField",
                "name": "extraShipping",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "fee",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "enabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2f47a7d02a2b5d5f80818b4d2705e72c",
    "id": null,
    "metadata": {},
    "name": "EditExtraShippingForm_UpdateExtraShippingMutation",
    "operationKind": "mutation",
    "text": "mutation EditExtraShippingForm_UpdateExtraShippingMutation(\n  $input: UpdateExtraShippingInput!\n) {\n  updateExtraShipping(input: $input) {\n    shop {\n      ...EditExtraShippingForm_shop\n      id\n    }\n  }\n}\n\nfragment EditExtraShippingForm_shop on Shop {\n  id\n  extraShipping {\n    id\n    fee\n    enabled\n  }\n}\n"
  }
};
})();
(node as any).hash = '85b23887a82c3d3b207ba146b7bb1cc0';
export default node;
