import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import { HomeQuery } from "__relay_artifacts__/HomeQuery.graphql";
import HomeScreen from "components/HomeScreen";

const query = graphql`
  query HomeQuery($first: Int!, $after: String) {
    ...HomeScreen_query
  }
`

type Props = {}
const Home: FC<Props> = () => {
  const { data } = useQuery<HomeQuery>(query, {first: 10});
  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <HomeScreen query={data} />
      </Container>
    </>
  )
}

export default Home;