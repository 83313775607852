import React, { FC } from "react";
import { styled, Select, MenuItem } from '@mui/material';
import { Field, useFormikContext, useField } from "formik"


const SelectBox = styled(Select)({
  backgroundColor: "#fff",
  width: "100%",
  WebkitAppearance: "none",
  appearance: "none",
  minHeight: "47px",
  borderRadius: "4px",
  outline: "none",
  border: "1px solid #ddd",
})

const Name = styled("p")({
  marginBottom: "15px",
  fontSize: "14px"
})

const kindOptions = [
  { value: "REGULAR", label: "普通" },
  { value: "CHECKING", label: "当座" },
];

type Props = {

}

const AccountKindSelect: FC<Props> = (props) => {
  const [field] = useField("kind")
  const { setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    const kind = kindOptions.find((p) => p.value === e.target.value)
    if (!kind) return;

    setFieldValue("kind", kind.value)
  }

  return (
    <>
      <Name>口座種別</Name>
      <SelectBox
        name="kind"
        value={field.value}
        onChange={handleChange}
      >
        {
          kindOptions.map((kind, i) => {
            return (
              <MenuItem value={kind.value} key={i}>{kind.label}</MenuItem>
            )
          })
        }
      </SelectBox>
    </>
  )
}

export default AccountKindSelect;