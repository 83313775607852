/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type DeleteLiveInput = {
    clientMutationId?: string | null;
    liveId: string;
};
export type LiveShowScreen_DeleteLiveMutationVariables = {
    input: DeleteLiveInput;
};
export type LiveShowScreen_DeleteLiveMutationResponse = {
    readonly deleteLive: {
        readonly deletedLiveId: string;
    } | null;
};
export type LiveShowScreen_DeleteLiveMutation = {
    readonly response: LiveShowScreen_DeleteLiveMutationResponse;
    readonly variables: LiveShowScreen_DeleteLiveMutationVariables;
};



/*
mutation LiveShowScreen_DeleteLiveMutation(
  $input: DeleteLiveInput!
) {
  deleteLive(input: $input) {
    deletedLiveId
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "DeleteLivePayload",
    "kind": "LinkedField",
    "name": "deleteLive",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "deletedLiveId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "LiveShowScreen_DeleteLiveMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "LiveShowScreen_DeleteLiveMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6a593b78aacc97ec108b58cb7d335a05",
    "id": null,
    "metadata": {},
    "name": "LiveShowScreen_DeleteLiveMutation",
    "operationKind": "mutation",
    "text": "mutation LiveShowScreen_DeleteLiveMutation(\n  $input: DeleteLiveInput!\n) {\n  deleteLive(input: $input) {\n    deletedLiveId\n  }\n}\n"
  }
};
})();
(node as any).hash = '60d3e14e1a5ec0f883dcd9140dda26a5';
export default node;
