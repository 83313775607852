import React, { FC, useState, useMemo } from "react";
import { styled, Button } from "@mui/material";
import { Field } from "formik";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { useFormikContext } from "formik"
import { PrefectureSelect_query$key } from "__relay_artifacts__/PrefectureSelect_query.graphql";
import { DetailedShipping } from "./EditShippingForm";

const fragment = graphql`
  fragment PrefectureSelect_query on Query {
    prefectures {
      id
      name
    }
  }
`

const SelectWrapper = styled("div")({
  position: "relative",
  "::after": {
    content: '"▼"',
    color: "grey",
    fontSize: "15px",
    top: "15px",
    right: "10px",
    position: "absolute"
  }
});

const SelectBox = styled(Field)({
  WebkitAppearance: "none",
  appearance: "none",
  height: "50px",
  borderRadius: "4px",
  outline: "none",
  padding: "0px 35px 0px 15px",
  border: "1px solid #ddd",
})

const ListItem = styled("div")({
  padding: "12px 20px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  border: "1px solid #ddd",
  marginBottom: "18px",
  borderRadius: "4px"
})

const StyledButton = styled(Button)({
  width: "150px",
  borderRadius: "4px",
  fontSize: "13px",
  backgroundColor: "#000",
  color: "#fff",
  fontWeight: "bold",
  padding: "12px 15px",
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
})

type Props = {
  query: PrefectureSelect_query$key
}

type Schema = {
  detailedShippings: DetailedShipping[]
}

const PrefectureSelect: FC<Props> = (props) => {
  const query = useFragment(fragment, props.query);
  const [selectedPrefecture, setSelectedPrefecture] = useState(null);
  const { setFieldValue, values } = useFormikContext<Schema>()

  const handleChange = (e) => {
    const prefecture = prefectures.find((p) => p.id === e.target.value)
    if (!prefecture) return;

    setSelectedPrefecture(prefecture)
  }

  const handleClick = () => {
    if (!selectedPrefecture) return;

    const shippings = values.detailedShippings.concat([{
      fee: null,
      prefecture: {
        id: selectedPrefecture.id,
        name: selectedPrefecture.name
      }
    }])

    setFieldValue('detailedShippings', shippings)
    setSelectedPrefecture(null)
  }

  const prefectures = useMemo(() => {
    return query.prefectures.filter((prefecture) => {
      const found = values.detailedShippings.find((shipping) => shipping.prefecture.id === prefecture.id)
      return !found;
    })
  }, [values.detailedShippings, query.prefectures])

  return (
    <>
      <ListItem>
        <SelectWrapper>
          <SelectBox as="select" onChange={handleChange}>
            <option>選択してください</option>
            {
              prefectures.map((prefecture, i) => {
                return (
                  <option value={prefecture.id} key={i}>{prefecture.name}</option>
                )
              })
            }
          </SelectBox>
        </SelectWrapper>
      </ListItem>

      <StyledButton onClick={handleClick}>
        地域を追加
      </StyledButton>
    </>
  )

}

export default PrefectureSelect;