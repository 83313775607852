/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type EditEmailForm_shop = {
    readonly id: string;
    readonly email: string | null;
    readonly " $refType": "EditEmailForm_shop";
};
export type EditEmailForm_shop$data = EditEmailForm_shop;
export type EditEmailForm_shop$key = {
    readonly " $data"?: EditEmailForm_shop$data;
    readonly " $fragmentRefs": FragmentRefs<"EditEmailForm_shop">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditEmailForm_shop",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "type": "Shop",
  "abstractKey": null
};
(node as any).hash = 'c29fde3f8b90008a506490e32469c1be';
export default node;
