import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import { LiveShowQuery } from "__relay_artifacts__/LiveShowQuery.graphql"
import { useParams } from "react-router-dom";
import LiveShowScreen from "components/LiveShowScreen"

const query = graphql`
  query LiveShowQuery($id: ID!) {
    viewer {
      shop {
        ...LiveShowScreen_shop
      }
    }
  }
`

type Props = {}
const LiveShow: FC<Props> = (props) => {
  const variables = { id: useParams().id }
  const { data } = useQuery<LiveShowQuery>(query, variables);
  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <LiveShowScreen shop={data.viewer.shop} />
      </Container>
    </>
  )
}

export default LiveShow;