import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import ItemsScreen from 'components/ItemsScreen';
import { ItemsQuery } from '__relay_artifacts__/ItemsQuery.graphql';

const query = graphql`
  query ItemsQuery {
    ...ItemsScreen_query
  }
`

type Props = {}
const Items: FC<Props> = () => {
  const { data } = useQuery<ItemsQuery>(query, {first: 10});
  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <ItemsScreen query={data} />
      </Container>
    </>
  )
}

export default Items;
