import { styled, Button, Dialog, DialogContent, CircularProgress } from "@mui/material";
import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "30px",
  textAlign: "center",
}));

const StyledInputFile = styled('input')({
  display: 'none',
});

const StyledButton = styled(Button)({
  backgroundColor: "#fafafa",
  color: "#000",
  fontSize: '12px',
  height: '50px',
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#fafafa",
  },
  width: "100%"
});

export type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const ItemCsvUploadDialog: FC<Props> = ({ isOpen, onClose }) => {
  const [csvFileName, setCsvFileName] = useState<string>()
  const [zipFileName, setZipFileName] = useState<string>()
  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const inputCsvFileRef = useRef<HTMLInputElement>(null)
  const inputZipFileRef = useRef<HTMLInputElement>(null)

  // アップロードが完了したら全ての値をリセットする
  useEffect(() => {
    if (uploaded) {
      if (inputCsvFileRef.current) {
        inputCsvFileRef.current.value = null
      }
      if (inputZipFileRef.current) {
        inputZipFileRef.current.value = null
      }
      setCsvFileName(undefined)
      setZipFileName(undefined)
      setUploading(false)
    }
  }, [uploaded])

  const handleCsvButtonClick = () => {
    inputCsvFileRef.current?.click()
  }

  const handleZipButtonClick = () => {
    inputZipFileRef.current?.click()
  }

  const handleChangeCsvFile = (event: ChangeEvent<HTMLInputElement>) => {
    setCsvFileName(event.target.files[0]?.name);
  }

  const handleChangeZipFile = (event: ChangeEvent<HTMLInputElement>) => {
    setZipFileName(event.target.files[0]?.name);
  }

  const handleClickUpload = async () => {
    setUploading(true)
    const formData = new FormData()
    formData.append('csv_file', inputCsvFileRef.current?.files?.[0] as File)

    if (inputZipFileRef.current?.files?.[0]) {
      formData.append('zip_file', inputZipFileRef.current?.files?.[0] as File)
    }

    const response = await fetch(`${process.env.REACT_APP_API_URL}/items/bulk_upsert`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      credentials: "include",
      body: formData,
    }).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        setUploaded(true)
        // alert("アップロードが完了しました。\n時間をおいてからブラウザをリロードしてください。")
      } else if (res.status === 400) {
        res.json().then((json) => {
          alert(json.message)
        })
      }
    })
  }

  const handleClose = () => {
    setUploaded(false)
    onClose()
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <StyledDialogContent>
        <h4 style={{ textAlign: "left", margin: "0px 0px 20px 0px" }}>CSV一括登録</h4>

        {
          uploaded ?
            <>
              <div style={{ marginBottom: 12 }}>
                <div>
                  一括登録が完了しましたら、ご登録いただいているメールアドレスに通知をお届けします。
                </div>
              </div>
            </>
            :
            uploading ?
              <>
                <div style={{ marginBottom: 12 }}>
                  <div>
                    アップロード中
                  </div>
                  <CircularProgress size={20} color='inherit' />
                </div>
              </>
              :
              <>
                <div style={{ marginBottom: 12 }}>
                  <StyledInputFile type="file" accept="text/csv" ref={inputCsvFileRef} onChange={handleChangeCsvFile} />
                  <StyledButton onClick={handleCsvButtonClick}>CSV ファイル</StyledButton>
                  <span>{csvFileName}</span>
                </div>
                <div style={{ marginBottom: 12 }}>
                  <StyledInputFile type="file" accept="application/zip" ref={inputZipFileRef} onChange={handleChangeZipFile} />
                  <StyledButton onClick={handleZipButtonClick}>画像ファイル（.zip）</StyledButton>
                  <span>{zipFileName}</span>
                </div>
                <div style={{ marginBottom: 0 }}>
                  <StyledButton onClick={handleClickUpload} disabled={!csvFileName}>アップロード</StyledButton>
                </div>
              </>
        }

      </StyledDialogContent>
    </Dialog>
  );
};
