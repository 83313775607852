/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type LiveStatus = "FINISHED" | "ONAIR" | "PENDING" | "%future added value";
export type LiveStatusText_live = {
    readonly id: string;
    readonly status: LiveStatus;
    readonly " $refType": "LiveStatusText_live";
};
export type LiveStatusText_live$data = LiveStatusText_live;
export type LiveStatusText_live$key = {
    readonly " $data"?: LiveStatusText_live$data;
    readonly " $fragmentRefs": FragmentRefs<"LiveStatusText_live">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LiveStatusText_live",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Live",
  "abstractKey": null
};
(node as any).hash = '7ffb22f994ff53ab87188e9524596737';
export default node;
