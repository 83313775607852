import React, { FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import AuctionForm from "./AuctionForm";
import { useFragment } from "relay-hooks";
import AuctionList from "./AuctionList";
import { AuctionsScreen_shop$key } from "__relay_artifacts__/AuctionsScreen_shop.graphql";
import { styled, Button } from "@mui/material";

const fragment = graphql`
  fragment AuctionsScreen_shop on Shop {
    live(id: $id) {
      id
      ...AuctionForm_live
      ...AuctionList_live
    }
  }
`

const StyledButton = styled(Button)({
  backgroundColor: "#ff2300",
  color: "#fff",
  fontSize: '12px',
  padding: "8px 14px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#ff2300",
  },
})

type Props = {
  shop: AuctionsScreen_shop$key
}

const AuctionsScreen: FC<Props> = (props) => {
  const [open, setOpen] = useState(false)
  const { live } = useFragment(fragment, props.shop);

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <div style={{ margin: "30px 0px" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginBottom: "30px" }}>
          <h3>オークション一覧</h3>

          <StyledButton onClick={() => { setOpen(true) }}>
            <span>作成する</span>
          </StyledButton>
        </div>

        <AuctionList live={live} />
        <AuctionForm live={live} open={open} handleClose={handleClose} />
      </div>
    </>
  )

}

export default AuctionsScreen;