/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ItemList_shop = {
    readonly items: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"ItemListItem_item">;
            } | null;
        } | null> | null;
    };
    readonly " $refType": "ItemList_shop";
};
export type ItemList_shop$data = ItemList_shop;
export type ItemList_shop$key = {
    readonly " $data"?: ItemList_shop$data;
    readonly " $fragmentRefs": FragmentRefs<"ItemList_shop">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemList_shop",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "onlyShown",
          "value": false
        }
      ],
      "concreteType": "ItemConnection",
      "kind": "LinkedField",
      "name": "items",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ItemListItem_item"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "items(onlyShown:false)"
    }
  ],
  "type": "Shop",
  "abstractKey": null
};
(node as any).hash = 'e521ef5260101698bf81665b4b72937e';
export default node;
