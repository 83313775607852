import React, { FC } from "react";
import { Container } from "@mui/material";
import PageAppBar from "components/PageAppBar";
import { graphql } from "babel-plugin-relay/macro";
import { useQuery } from "relay-hooks";
import { useParams } from "react-router-dom";
import { AuctionsQuery } from "__relay_artifacts__/AuctionsQuery.graphql";
import AuctionsScreen from "components/AuctionsScreen"

const query = graphql`
  query AuctionsQuery($id: ID!, $first: Int!, $after: String) {
    viewer {
      shop {
        ...AuctionsScreen_shop
      }
    }
  }
`


type Props = {}
const Auctions: FC<Props> = (props) => {
  const variables = { id: useParams().id, first: 30 }
  const { data } = useQuery<AuctionsQuery>(query, variables);
  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <AuctionsScreen shop={data.viewer.shop} />
      </Container>
    </>
  )
}

export default Auctions;