import React, { FC } from "react";
import { Button, Dialog, DialogContent, styled, CircularProgress } from "@mui/material";
import { Formik, Field, ErrorMessage, Form } from "formik";
import * as Yup from "yup";
import StyledErrorMessage from "components/Common/StyledErrorMessage";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { PasswordConfirmDialog_UpdateShopEmailMutation } from "__relay_artifacts__/PasswordConfirmDialog_UpdateShopEmailMutation.graphql";

const mutation = graphql`
  mutation PasswordConfirmDialog_UpdateShopEmailMutation($input: UpdateShopEmailInput!) {
    updateShopEmail(input: $input) {
      shop {
        id
        email
      }
    }
  }
`

const validationSchema = Yup.object({
  password: Yup.string().required("コードを入力してください")
});

const EmailSent = styled("p")({
  fontSize: "15px",
  marginBottom: "30px",
  fontWeight: "bold",
});

const StyledField = styled(Field)({
  height: "55px",
  width: "100%",
  fontSize: "16px",
  borderRadius: "4px",
  outline: "none",
  textAlign: "center",
  padding: "0px 18px",
  border: "#ebebeb solid 1px",
});

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "50px 20px !important",
  [theme.breakpoints.up("sm")]: {
    padding: "50px 30px !important"
  },
  textAlign: "center",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "100%",
  height: "55px",
  borderRadius: "4px",
  fontSize: "13px",
  backgroundColor: "#000",
  color: "#fff",
  fontWeight: "bold",
  padding: "12px",
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
}));

type Props = {
  open: boolean,
  email: string,
  handleClose: () => void,
};

export const PasswordConfirmDialog: FC<Props> = (props) => {
  const { open, email, handleClose } = props;
  const [mutate] = useMutation<PasswordConfirmDialog_UpdateShopEmailMutation>(mutation);

  const onSubmit = async (values) => {
    await mutate({
      variables: {
        input: {
          password: values.password,
          email: email
        }
      },
      onCompleted: (res) => {
        handleClose()
      }
    });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <StyledDialogContent>
        <EmailSent>認証コードを入力してください</EmailSent>
        <Formik
          initialValues={{ password: "" }}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
        >
          {(formik) => {
            return (
              <Form>
                <StyledField type="text" name="password" placeholder="000000"></StyledField>
                <div style={{ marginTop: "12px" }}>
                  <ErrorMessage name="password" component={StyledErrorMessage} />
                  <StyledButton type="submit">
                    {
                      formik.isSubmitting
                        ? <CircularProgress size={20} />
                        : "送信"
                    }
                  </StyledButton>
                </div>
              </Form>
            )
          }}

        </Formik>
      </StyledDialogContent>
    </Dialog >
  )
}

export default PasswordConfirmDialog;