/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AuctionStatus = "CANCELED" | "DROPPED" | "FINISHED" | "PENDING" | "%future added value";
export type AuctionStatusText_auction = {
    readonly id: string;
    readonly status: AuctionStatus;
    readonly " $refType": "AuctionStatusText_auction";
};
export type AuctionStatusText_auction$data = AuctionStatusText_auction;
export type AuctionStatusText_auction$key = {
    readonly " $data"?: AuctionStatusText_auction$data;
    readonly " $fragmentRefs": FragmentRefs<"AuctionStatusText_auction">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionStatusText_auction",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Auction",
  "abstractKey": null
};
(node as any).hash = 'e23988ac8fda17e24bc0db0341324728';
export default node;
