/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountScreen_query = {
    readonly viewer: {
        readonly id: string;
    } | null;
    readonly " $refType": "AccountScreen_query";
};
export type AccountScreen_query$data = AccountScreen_query;
export type AccountScreen_query$key = {
    readonly " $data"?: AccountScreen_query$data;
    readonly " $fragmentRefs": FragmentRefs<"AccountScreen_query">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AccountScreen_query",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "viewer",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
(node as any).hash = 'c34549a937ffc0fda5272d1b88ef4728';
export default node;
