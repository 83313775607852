import React, { FC, useMemo } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { ShippingStatusLabel_order$key } from "__relay_artifacts__/ShippingStatusLabel_order.graphql"
import { TransferStatusLabel_transfer$key } from "__relay_artifacts__/TransferStatusLabel_transfer.graphql"
import TransferList from ".";

const fragment = graphql`
  fragment TransferStatusLabel_transfer on Transfer {
    status
  }
`

type Props = {
  transfer: TransferStatusLabel_transfer$key
}

const ShippingStatusLabel: FC<Props> = (props) => {
  const transfer = useFragment(fragment, props.transfer);
  let statusText, backgroundColor, color;

  [statusText, backgroundColor, color] = useMemo(() => {
    switch (transfer.status) {
      case "PENDING":
        return ["振込中", "#D2F4FD", "#5EA3B6"];
      case "COMPLETED":
        return ["振込完了", "#EAE6FF", "#5B4DAE"];
    }
  }, [transfer]);

  return (
    <span
      style={{
        display: "inline-block",
        padding: "5px 10px",
        borderRadius: "16px",
        fontSize: "11px",
        fontWeight: "bold",
        backgroundColor: backgroundColor,
        color: color
      }}
    >
      {statusText}
    </span>
  )

}

export default ShippingStatusLabel;