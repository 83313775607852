/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type DetailedQueryVariables = {};
export type DetailedQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"AccountShippingDetailedScreen_query">;
};
export type DetailedQuery = {
    readonly response: DetailedQueryResponse;
    readonly variables: DetailedQueryVariables;
};



/*
query DetailedQuery {
  ...AccountShippingDetailedScreen_query
}

fragment AccountShippingDetailedScreen_query on Query {
  viewer {
    id
    shop {
      id
      ...EditShippingForm_shop
    }
  }
  ...EditShippingForm_query
}

fragment EditShippingForm_query on Query {
  ...PrefectureSelect_query
}

fragment EditShippingForm_shop on Shop {
  id
  shipping {
    id
    fee
  }
  detailedShippings {
    id
    fee
    prefecture {
      id
      name
    }
  }
}

fragment PrefectureSelect_query on Query {
  prefectures {
    id
    name
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fee",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DetailedQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccountShippingDetailedScreen_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DetailedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Shop",
            "kind": "LinkedField",
            "name": "shop",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopShipping",
                "kind": "LinkedField",
                "name": "shipping",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ShopDetailedShipping",
                "kind": "LinkedField",
                "name": "detailedShippings",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Prefecture",
                    "kind": "LinkedField",
                    "name": "prefecture",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Prefecture",
        "kind": "LinkedField",
        "name": "prefectures",
        "plural": true,
        "selections": (v2/*: any*/),
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "215fda2d1459c394ec468925f154e845",
    "id": null,
    "metadata": {},
    "name": "DetailedQuery",
    "operationKind": "query",
    "text": "query DetailedQuery {\n  ...AccountShippingDetailedScreen_query\n}\n\nfragment AccountShippingDetailedScreen_query on Query {\n  viewer {\n    id\n    shop {\n      id\n      ...EditShippingForm_shop\n    }\n  }\n  ...EditShippingForm_query\n}\n\nfragment EditShippingForm_query on Query {\n  ...PrefectureSelect_query\n}\n\nfragment EditShippingForm_shop on Shop {\n  id\n  shipping {\n    id\n    fee\n  }\n  detailedShippings {\n    id\n    fee\n    prefecture {\n      id\n      name\n    }\n  }\n}\n\nfragment PrefectureSelect_query on Query {\n  prefectures {\n    id\n    name\n  }\n}\n"
  }
};
})();
(node as any).hash = '19b31d39b06fc9ab23a2024e3d72e7e5';
export default node;
