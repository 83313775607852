/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type SessionVia = "APP" | "WEB" | "%future added value";
export type CreateAppleSessionInput = {
    clientMutationId?: string | null;
    token: string;
    via: SessionVia;
};
export type SignInWithAppleButton_CreateAppleSessionMutationVariables = {
    input: CreateAppleSessionInput;
};
export type SignInWithAppleButton_CreateAppleSessionMutationResponse = {
    readonly createAppleSession: {
        readonly token: string;
    } | null;
};
export type SignInWithAppleButton_CreateAppleSessionMutation = {
    readonly response: SignInWithAppleButton_CreateAppleSessionMutationResponse;
    readonly variables: SignInWithAppleButton_CreateAppleSessionMutationVariables;
};



/*
mutation SignInWithAppleButton_CreateAppleSessionMutation(
  $input: CreateAppleSessionInput!
) {
  createAppleSession(input: $input) {
    token
    id
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "token",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SignInWithAppleButton_CreateAppleSessionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAppleSessionPayload",
        "kind": "LinkedField",
        "name": "createAppleSession",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SignInWithAppleButton_CreateAppleSessionMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CreateAppleSessionPayload",
        "kind": "LinkedField",
        "name": "createAppleSession",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eed515952a2a4c32fa018ad43a24e3c0",
    "id": null,
    "metadata": {},
    "name": "SignInWithAppleButton_CreateAppleSessionMutation",
    "operationKind": "mutation",
    "text": "mutation SignInWithAppleButton_CreateAppleSessionMutation(\n  $input: CreateAppleSessionInput!\n) {\n  createAppleSession(input: $input) {\n    token\n    id\n  }\n}\n"
  }
};
})();
(node as any).hash = 'fa0ee1ad5bafb3513602f05ed00014ec';
export default node;
