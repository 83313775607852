import React, { FC } from "react";
import PageAppBar from "components/PageAppBar";
import { Container } from "@mui/material";
import NewLiveScreen from "components/NewLiveScreen"
import { graphql } from "babel-plugin-relay/macro";
import { LivesNewQuery } from "__relay_artifacts__/LivesNewQuery.graphql"
import { useQuery } from "relay-hooks";

type Props = {}

const query = graphql`
  query LivesNewQuery {
    ...NewLiveScreen_query
  }
`

const LivesNew: FC<Props> = (props) => {
  const { data } = useQuery<LivesNewQuery>(query);
  if (!data) return <></>

  return (
    <>
      <PageAppBar />
      <Container maxWidth="md">
        <NewLiveScreen query={data} />
      </Container>
    </>
  )

}

export default LivesNew