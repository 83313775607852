import React, { FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { HomeScreen_query$key } from "__relay_artifacts__/HomeScreen_query.graphql";
import LiveList from "./LiveList";
import { styled, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useHistory } from 'react-router-dom'
import MessageDialog from "components/Common/MessageDialog";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const fragment = graphql`
  fragment HomeScreen_query on Query {
    viewer {
      id

      shop {
        readyForLive
        ...LiveList_shop
      }
    }
  }
`

const StyledButton = styled(Button)({
  backgroundColor: "#ff2300",
  color: "#fff",
  fontSize: '12px',
  padding: "8px 14px",
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#ff2300",
  },
})

type Props = {
  query: HomeScreen_query$key
}

const HomeScreen: FC<Props> = (props) => {
  const query = useFragment(fragment, props.query);

  return (
    <>
      <div style={{ padding: "30px 0px 30px 0px" }}>
        {/* <Link to="/items" style={{ padding: "20px 15px 20px 15px", display: 'flex', alignItems: "center", justifyContent: 'space-between', backgroundColor: "#fff", borderRadius: "4px", marginBottom: "15px" }}>
          <p style={{ margin: "0px" }}>アイテムリスト</p>
          <KeyboardArrowRightIcon />
        </Link> */}

        <Link to={`/orders`} style={{ padding: "20px 15px 20px 15px", display: 'flex', alignItems: "center", justifyContent: 'space-between', backgroundColor: "#fff", borderRadius: "4px", marginBottom: "15px" }}>
          <p style={{ margin: "0px" }}>オーダーリスト</p>
          <KeyboardArrowRightIcon />
        </Link>

        {/* <div style={{ padding: "20px 15px 20px 15px", display: 'flex', alignItems: "center", justifyContent: 'space-between', backgroundColor: "#fff", borderRadius: "4px", marginBottom: "15px" }}>
          <p style={{ margin: "0px" }}>ライブリスト</p>
          <KeyboardArrowRightIcon />
        </div> */}
      </div>
    </>
  )
}

export default HomeScreen;
