import React, { FC } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import { AccountShippingDetailedScreen_query$key } from "__relay_artifacts__/AccountShippingDetailedScreen_query.graphql";
import EditExtraShippingForm from "./EditExtraShippingForm";

const fragment = graphql`
  fragment AccountShippingExtraScreen_query on Query {
    viewer {
      id

      shop {
        ...EditExtraShippingForm_shop
      }
    }
  }
`

type Props = {
  query: AccountShippingDetailedScreen_query$key
}

const AccountShippingExtraScreen: FC<Props> = (props) => {
  const query = useFragment(fragment, props.query);

  return (
    <>
      <div style={{ margin: "50px 0px" }}>
        <div style={{marginBottom: "30px"}}>
          <h3 style={{ marginBottom: "30px" }}>追加送料</h3>
          <p style={{fontSize: "14px", lineHeight: "1.7"}}>同じライブ配信内で複数個購入された場合、二点目以降、追加で発生する送料を別途設定できます。設定しない場合、一点目と同じ送料が二点目以降にも発生します。</p>
        </div>
        <EditExtraShippingForm shop={query.viewer.shop} />
      </div>
    </>
  )

}

export default AccountShippingExtraScreen;
