/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type ItemListItem_item = {
    readonly id: string;
    readonly name: string;
    readonly " $refType": "ItemListItem_item";
};
export type ItemListItem_item$data = ItemListItem_item;
export type ItemListItem_item$key = {
    readonly " $data"?: ItemListItem_item$data;
    readonly " $fragmentRefs": FragmentRefs<"ItemListItem_item">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ItemListItem_item",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Item",
  "abstractKey": null
};
(node as any).hash = '8f39a81ab46b7c0af973b8df85bfc9b0';
export default node;
