/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AccountQueryVariables = {};
export type AccountQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"AccountScreen_query">;
};
export type AccountQuery = {
    readonly response: AccountQueryResponse;
    readonly variables: AccountQueryVariables;
};



/*
query AccountQuery {
  ...AccountScreen_query
}

fragment AccountScreen_query on Query {
  viewer {
    id
  }
}
*/

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "AccountScreen_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AccountQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d9fe9eecd3bfe888f53cd5afb014da16",
    "id": null,
    "metadata": {},
    "name": "AccountQuery",
    "operationKind": "query",
    "text": "query AccountQuery {\n  ...AccountScreen_query\n}\n\nfragment AccountScreen_query on Query {\n  viewer {\n    id\n  }\n}\n"
  }
};
(node as any).hash = 'f7655cb63e86478563e8c9ea7d8d3296';
export default node;
