import * as yup from "yup";
import { AnyObject, Maybe } from "yup/lib/types"

yup.addMethod<yup.StringSchema>(yup.string, 'katakana', function () {
  return this.test('katakana', '全角カタカナで入力して下さい', (value) => {
    if (value == null || value === '') { return true; }
    return value.match(/^[ァ-ヶー　 ]+$/)?.length > 0;
  });
});

declare module "yup" {
  interface StringSchema<
    TType extends Maybe<string> = string | undefined,
    TContext extends AnyObject = AnyObject,
    TOut extends TType = TType
  > extends yup.BaseSchema<TType, TContext, TOut> {
    emptyAsUndefined(): StringSchema<TType, TContext>;
  }
}

export default yup;