import React, { FC, useState } from "react";
import { useFragment } from "relay-hooks";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { styled, Button, CircularProgress } from "@mui/material";
import * as Yup from "yup";
import { useSnackbar } from 'notistack';
import Switch from '@mui/material/Switch';
import { useHistory } from 'react-router-dom'
import StyledErrorMessage from "components/Common/StyledErrorMessage";
import { EditExtraShippingForm_shop$key } from "__relay_artifacts__/EditExtraShippingForm_shop.graphql";
import { EditExtraShippingForm_UpdateExtraShippingMutation } from "__relay_artifacts__/EditExtraShippingForm_UpdateExtraShippingMutation.graphql";

const schema = Yup.object({
  fee: Yup.string().required("送料を入力してください"),
});

const fragment = graphql`
  fragment EditExtraShippingForm_shop on Shop {
    id

    extraShipping {
      id
      fee
      enabled
    }
  }
`

const mutation = graphql`
  mutation EditExtraShippingForm_UpdateExtraShippingMutation($input: UpdateExtraShippingInput!) {
    updateExtraShipping(input: $input) {
      shop {
        ...EditExtraShippingForm_shop
      }
    }
  }
`

const ListItem = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "4px",
  padding: "10px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: "20px"
})

const StyledField = styled(Field)(({ theme }) => ({
  height: "50px",
  width: "120px",
  fontSize: "15px",
  borderRadius: "4px",
  outline: "none",
  padding: "0px 18px",
  border: "none",
  textAlign: 'right',
  backgroundColor: "#fafafa"
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "200px",
  borderRadius: "4px",
  fontSize: "13px",
  backgroundColor: "#000",
  color: "#fff",
  fontWeight: "bold",
  padding: "12px 15px",
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
}));

type Props = {
  shop: EditExtraShippingForm_shop$key
}

const EditExtraShippingForm: FC<Props> = (props) => {
  const history = useHistory();
  const shop = useFragment(fragment, props.shop);
  const [mutate] = useMutation<EditExtraShippingForm_UpdateExtraShippingMutation>(mutation);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values) => {

    console.log(values.enabled)

    await mutate({
      variables: {
        input: {
          fee: parseInt(values.fee),
          enabled: values.enabled
        }
      },
      onCompleted: (res) => {
        enqueueSnackbar("追加送料の設定に成功しました", {
          variant: "success"
        })

        history.push("/account/shipping");
      },
      onError: (errors) => {
        if (errors?.length > 0) {
          enqueueSnackbar(errors[0].message, {
            variant: "error"
          })
        }
      }
    });
  }

  return (
    <>
      {
        <Formik
          initialValues={{
            fee: shop.extraShipping?.fee ?? 100,
            enabled: shop.extraShipping?.enabled ?? false
          }}
          onSubmit={onSubmit}
          validateOnBlur={false}
          validateOnChange={false}
          validationSchema={schema}
        >
          {(formik) => {
            return (
              <Form>
                <ListItem>
                  <p style={{ fontSize: "15px", fontWeight: "bold" }}>追加の送料を設定する</p>

                  <Switch
                    name="enabled"
                    checked={formik.values.enabled}
                    onChange={() => {

                      formik.setFieldValue("enabled", !formik.values.enabled)

                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </ListItem>

                {
                  formik.values.enabled && <>
                    <ListItem>
                      <p style={{ fontSize: "15px", fontWeight: "bold" }}>追加送料</p>
                      <div>
                        <StyledField type="text" name="fee" placeholder="100" />
                        <span style={{ marginLeft: "10px" }}>円</span>
                      </div>
                    </ListItem>
                  </>
                }

                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px" }}>
                  <div style={{ textAlign: "center" }}>
                    <ErrorMessage name="fee" component={StyledErrorMessage} />

                    <StyledButton type="submit">
                      {
                        formik.isSubmitting
                          ? <CircularProgress size={20} />
                          : "保存する"
                      }
                    </StyledButton>
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      }
    </>
  )
}

export default EditExtraShippingForm;