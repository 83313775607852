import React, { FC, SetStateAction, useState, useCallback } from "react";
import { styled, Button, formHelperTextClasses } from "@mui/material";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useFormikContext } from "formik"

type FormValues = {
  imageData: string
}

const ImageWrapper = styled("div")(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: '300px',
  },
}));

const EmptyPhotoWrapper = styled("div")({
  height: "200px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#eeeeee",
  borderRadius: "4px",
  cursor: "pointer"
})

type Props = {
}

const ImageForm: FC<Props> = (props) => {
  const { setFieldValue } = useFormikContext()
  const formikHelpers = useFormikContext<FormValues>();

  const readFile = async (file: File | Blob): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result as string));
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange = async (e) => {
    try {
      const file = e.target.files[0];
      if (file.size > 5242880) {
        alert("画像サイズを5MG以内にしてください");
        return
      };

      const image = await readFile(file)
      setFieldValue("imageData", image)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      {
        //<CropImageDialog open={open} handleClose={handleClose} imageData={imageData} setCroppedImage={setCroppedImage} />
      }

      {
        formikHelpers.values.imageData === ''
          ?
          <>
            <label htmlFor="liveImageData">
              <EmptyPhotoWrapper>
                <InsertPhotoIcon />
              </EmptyPhotoWrapper>
            </label>

            <input
              accept="image/*"
              style={{ display: "none" }}
              id="liveImageData"
              onChange={handleImageChange}
              type="file"
            />
          </>
          :
          <ImageWrapper>
            <img src={formikHelpers.values.imageData} style={{ width: "100%" }} />
          </ImageWrapper>
      }
    </>
  )
}

export default ImageForm;