/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";

export type BranchAutoCompleteForm_branches_QueryVariables = {
    bankId: string;
    name?: string | null;
};
export type BranchAutoCompleteForm_branches_QueryResponse = {
    readonly branches: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string | null;
            } | null;
        } | null> | null;
    };
};
export type BranchAutoCompleteForm_branches_Query = {
    readonly response: BranchAutoCompleteForm_branches_QueryResponse;
    readonly variables: BranchAutoCompleteForm_branches_QueryVariables;
};



/*
query BranchAutoCompleteForm_branches_Query(
  $bankId: ID!
  $name: String
) {
  branches(first: 5, bankId: $bankId, name: $name) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "bankId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "bankId",
        "variableName": "bankId"
      },
      {
        "kind": "Literal",
        "name": "first",
        "value": 5
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "concreteType": "BranchConnection",
    "kind": "LinkedField",
    "name": "branches",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BranchEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Branch",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BranchAutoCompleteForm_branches_Query",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BranchAutoCompleteForm_branches_Query",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "22974e30b6e2bd1054e33f5820231321",
    "id": null,
    "metadata": {},
    "name": "BranchAutoCompleteForm_branches_Query",
    "operationKind": "query",
    "text": "query BranchAutoCompleteForm_branches_Query(\n  $bankId: ID!\n  $name: String\n) {\n  branches(first: 5, bankId: $bankId, name: $name) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();
(node as any).hash = 'de5003f9fd1aeee258d9381c52468fbd';
export default node;
