import React, { FC } from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type Props = {
}

const Wrapper = styled("div")({
  backgroundColor: "#fff",
  borderRadius: "4px",
  padding: "10px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
})

const Name = styled("p")({
  fontSize: "15px",
  fontWeight: "500"
})

const LinkWrapper = styled("div")({
  marginBottom: "15px"
})

const AccountShippingScreen: FC<Props> = (props) => {
  return (
    <>
      <div style={{ margin: "50px 0px" }}>
        <h3 style={{ marginBottom: "30px" }}>送料設定</h3>

        <LinkWrapper>
          <Link to="/account/shipping/detailed">
            <Wrapper>
              <Name>基本設定</Name>
              <KeyboardArrowRightIcon />
            </Wrapper>
          </Link>
        </LinkWrapper>

        <LinkWrapper>
          <Link to="/account/shipping/extra">
            <Wrapper>
              <Name>追加送料</Name>
              <KeyboardArrowRightIcon />
            </Wrapper>
          </Link>
        </LinkWrapper>
      </div>
    </>
  )

}

export default AccountShippingScreen;
