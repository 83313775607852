import React, { FC, useState } from "react";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { graphql } from "babel-plugin-relay/macro";
import { useMutation } from "relay-hooks";
import { styled, Button, CircularProgress } from "@mui/material";
import StyledErrorMessage from "components/Common/StyledErrorMessage";
import * as Yup from "yup";
import { useFragment } from "relay-hooks";
import { EditEmailForm_shop$key } from "__relay_artifacts__/EditEmailForm_shop.graphql";
import { EditEmailForm_CreatePasswordMutation, PasswordMethod } from "__relay_artifacts__/EditEmailForm_CreatePasswordMutation.graphql";
import PasswordConfirmDialog from "./PasswordConfirmDialog";

const emailSchema = Yup.object({
  email: Yup.string().required("メールアドレスを入力してください").email("正しいメールアドレスを入力してください"),
});

const fragment = graphql`
  fragment EditEmailForm_shop on Shop {
    id
    email
  }
`

const mutation = graphql`
  mutation EditEmailForm_CreatePasswordMutation($input: CreatePasswordInput!) {
    createPassword(input: $input) {
      password {
        id
      }
    }
  }
`


const StyledField = styled(Field)(({ theme }) => ({
  height: "50px",
  width: "100%",
  fontSize: "14px",
  borderRadius: "4px",
  outline: "none",
  padding: "0px 18px",
  border: "solid 1px #000",
}));

const StyledButton = styled(Button)(({ theme }) => ({
  width: "150px",
  borderRadius: "4px",
  fontSize: "13px",
  backgroundColor: "#000",
  color: "#fff",
  fontWeight: "bold",
  padding: "12px",
  "&:hover": {
    backgroundColor: "#000 !important"
  },
  "&:disabled": {
    backgroundColor: "#000 !important"
  }
}));

type Props = {
  shop: EditEmailForm_shop$key
}

const EditEmailForm: FC<Props> = (props) => {
  const [open, setOpen] = useState(false)
  const shop = useFragment(fragment, props.shop);
  const [mutate] = useMutation<EditEmailForm_CreatePasswordMutation>(mutation);
  const [email, setEmail] = useState('')

  const onSubmit = async (values) => {
    await mutate({
      variables: {
        input: {
          method: "EMAIL",
          email: values.email,
        }
      },
      onCompleted: (res) => {
        setEmail(values.email)
        setOpen(true)
      }
    });
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <PasswordConfirmDialog open={open} email={email} handleClose={handleClose} />

      <Formik
        initialValues={{ email: shop.email ?? "" }}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
        validationSchema={emailSchema}
      >
        {(formik) => {
          return (
            <Form>
              <StyledField type="text" name="email" placeholder="メールアドレスを入力" />
              <ErrorMessage name="email" component={StyledErrorMessage} />

              <div style={{ marginTop: "15px", display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                <StyledButton type="submit">
                  {
                    formik.isSubmitting
                      ? <CircularProgress size={20} />
                      : "認証コードを送信"
                  }
                </StyledButton>
              </div>
            </Form>
          )
        }}
      </Formik>
    </>

  )

}

export default EditEmailForm;