import React, { FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "relay-hooks";
import ItemList from './ItemList';
import { ItemsScreen_query$key } from '__relay_artifacts__/ItemsScreen_query.graphql';
import { ItemCsvTemplateDownloadButton } from './ItemCsvTemplateDownloadButton';
import { Settings } from "@mui/icons-material";
import { styled, Button, Dialog, DialogContent } from "@mui/material";
import { ItemCsvUploadDialog } from './ItemCsvUploadDialog';

const fragment = graphql`
  fragment ItemsScreen_query on Query {
    viewer {
      id

      shop {
        ...ItemList_shop
      }
    }
  }
`

type Props = {
  query: ItemsScreen_query$key
}

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "30px",
  textAlign: "center",
}));

const StyledButton = styled(Button)({
  backgroundColor: "#fafafa",
  color: "#000",
  fontSize: '12px',
  height: '50px',
  fontWeight: "bold",
  "&:hover": {
    backgroundColor: "#fafafa",
  },
  width: "100%"
});

const ItemsScreen: FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenUploadDialog, setIsOpenUploadDialog] = useState(false)
  const query = useFragment(fragment, props.query);

  const handleClose = () => {
    setIsOpen(false)
  }

  const handleCloseUploadDialog = () => {
    setIsOpenUploadDialog(false)
  }

  const handleClick = () => {
    setIsOpenUploadDialog(true);
    setIsOpen(false);
  }

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose}>
        <StyledDialogContent>
          <h4 style={{ textAlign: "left", margin: "0px 0px 20px 0px" }}>CSV一括登録</h4>

          <div style={{ marginBottom: 12 }}>
            <ItemCsvTemplateDownloadButton />
          </div>
          <div style={{ marginBottom: 0 }}>
            <StyledButton onClick={handleClick}>アイテム一括登録 CSV アップロード</StyledButton>
          </div>
        </StyledDialogContent>
      </Dialog>

      <ItemCsvUploadDialog isOpen={isOpenUploadDialog} onClose={handleCloseUploadDialog} />

      <div style={{ width: '100%', margin: "30px 0px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h3>アイテム一覧</h3>

        <div onClick={() => { setIsOpen(true) }} style={{ cursor: "pointer" }}>
          <Settings style={{ fontSize: "25px", color: "#000e2b" }} />
        </div>
      </div>

      <ItemList shop={query.viewer.shop} />
    </>
  )
}

export default ItemsScreen;
