import React, { FC, useState } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { ItemListItem_item$key } from "__relay_artifacts__/ItemListItem_item.graphql";
import { useFragment } from "relay-hooks";
import { Link } from "react-router-dom";
import { styled, ListItem  } from "@mui/material";

const fragment = graphql`
  fragment ItemListItem_item on Item {
    id
    name
  }
`

const StyledListItem = styled(ListItem)({
  borderRadius: "8px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "10px",
  padding: "16px 16px",
});

const FlexFirst = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "calc(100% - 500px)"
})

const Name = styled("span")(({ theme }) => ({
  width: "220px",
  minWidth: "110px",
  fontSize: "13px",
  fontWeight: "bold",
  [theme.breakpoints.down("sm")]: {
    width: "100px",
    minWidth: "100px",
  },
}));

type Props = {
  item: ItemListItem_item$key
}

const ItemListItem: FC<Props> = (props) => {
  const item = useFragment(fragment, props.item);

  return (
    <Link to="/items">
      <StyledListItem button={false as any}>
        <FlexFirst>
          <Name>{item.name}</Name>
        </FlexFirst>
      </StyledListItem>
    </Link>
  )
}

export default ItemListItem;
