/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type AuctionForm_live = {
    readonly id: string;
    readonly " $refType": "AuctionForm_live";
};
export type AuctionForm_live$data = AuctionForm_live;
export type AuctionForm_live$key = {
    readonly " $data"?: AuctionForm_live$data;
    readonly " $fragmentRefs": FragmentRefs<"AuctionForm_live">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AuctionForm_live",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Live",
  "abstractKey": null
};
(node as any).hash = 'ef82ed662b92cbbfc0ccc88dec8f853c';
export default node;
