import React, { FC } from 'react';
import logo from './logo.svg';
import theme from "theme";
import './App.css';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Router from "components/Router";
import { CssBaseline } from "@mui/material";
import { RelayEnvironmentProvider } from "relay-hooks";
import { initEnvironment } from "lib/relay";
import { UserProvider } from "hooks/UserContext";
import { SnackbarProvider } from 'notistack';
import { ApolloLink, ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const EnvironmentProvider: FC = ({ children }) => {
  const handleUnauthorize = () => {
    console.log("TODO")
  }

  const environment = initEnvironment(handleUnauthorize);

  return (
    <RelayEnvironmentProvider environment={environment}>
      {children}
    </RelayEnvironmentProvider>
  )
}

const ApiProvider: FC = ({ children }) => {
  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_URL}/api/graphql`
  })

  const authLink = setContext(async (_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    }
  });

  const links = ApolloLink.from([
    authLink,
    httpLink
  ])

  const client = new ApolloClient({
    link: links,
    cache: new InMemoryCache(),
  })

  return (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  )
}

function App() {

  return (
    <BrowserRouter>
      <UserProvider>
        <EnvironmentProvider>
          <ApiProvider>
            <ThemeProvider theme={theme}>
              <SnackbarProvider
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                hideIconVariant={true}
              >
                <CssBaseline />
                <Router />
              </SnackbarProvider>
            </ThemeProvider>
          </ApiProvider>
        </EnvironmentProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
